import { Controller } from "stimulus";
import { Formio } from "formiojs";
import BaseFileModule from "./shared_modules/base_file_module.js";
import FormStateModule from "./shared_modules/form_state_module.js";
import NotificationModule from "./shared_modules/notification_module.js";
import FormSubmissionModule from "./shared_modules/form_submission_module.js";
import CornerstoneGradingModule from "./shared_modules/cornerstone_grading_module.js";

export default class extends Controller {
  static targets = [
    "viewer",
    "url",
    "form",
    "path",
    "agreement",
    "imageTag",
    "submit",
    "update",
    "menu",
    "cornerstone",
    "formSection",
    "menuIcon",
    "cornerstoneIcon",
    "questionArea",
    "loadingImage",
  ];

  async initialize() {
    // Define Targets
    const elem = this.viewerTarget;
    this.imagesMenu = this.menuTarget;
    this.cornerstoneImages = this.cornerstoneTarget;
    this.formSection = this.formSectionTarget;
    this.gradingFormElem = this.formTarget;
    this.gradingFormAgreementElem = this.agreementTarget;
    this.imageTag = this.imageTagTarget;
    this.viewerElement = elem;
    this.gradingSaveUrl =
      document.location.origin + this.data.get("updatePath") + ".json";
    this.gradingPreviousUrl =
      document.location.origin + this.data.get("previousAns") + ".json";
    this.formMode = this.data.get("mode");
    this.gradingFormUserId = this.data.get("userId");
    this.pdfIcon = this.data.get("pdfIcon");
    this.gradingFormStackId = this.data.get("stackId");
    this.viewerStatus = this.data.get("viewerStatus");
    this.releaseEye = this.data.get("releaseEye");
    const imageKeys = this.data.get("imageKeys");
    this.gradingFormUserId = this.data.get("userId");
    this.gradingFormStackId = this.data.get("stackId");
    this.apiUrl = `${this.data.get("apiUrl")}iol/angle-calculation`;
    if (imageKeys && imageKeys.trim() !== "[]") {
      // Split the string by commas and remove any leading/trailing spaces, then filter out empty strings
      this.gradingImages = imageKeys
        .split(",")
        .map((key) => key.trim())
        .filter(Boolean);
    } else {
      // If imageKeys is not provided or is empty/contains only [], set this.gradingImages to an empty array
      this.gradingImages = [];
    }
    this.images = [];
    this.gradingFormVersionId = this.data.get("versionId");
    // load previously saved grading data if available
    this.annotation = JSON.parse(this.data.get("annotation"));
    this.gradingFormSchema = JSON.parse(this.data.get("gradingForm"));
    this.StudyEye = this.data.get("studyEye");
    this.CheckEye = this.data.get("checkEye");
    if (this.CheckEye == "Study Eye") {
      const updatedComponents = [];
      this.gradingFormSchema.components.forEach((multiple_trials) => {
        if (
          multiple_trials["key"].slice(-2).toLowerCase() == this.StudyEye ||
          this.StudyEye == "ou" ||
          (multiple_trials["key"].slice(-2).toLowerCase() != "od" &&
            multiple_trials["key"].slice(-2).toLowerCase() != "os")
        ) {
          updatedComponents.push(multiple_trials);
        }
      });
      this.gradingFormSchema["components"] = updatedComponents;
    } else {
      const updatedComponents = [];
      this.gradingFormSchema.components.forEach((multiple_trials) => {
        if (
          multiple_trials["key"].slice(-2).toLowerCase() == this.releaseEye ||
          this.releaseEye == "ou" ||
          (multiple_trials["key"].slice(-2).toLowerCase() != "od" &&
            multiple_trials["key"].slice(-2).toLowerCase() != "os")
        ) {
          updatedComponents.push(multiple_trials);
        }
      });
      this.gradingFormSchema["components"] = updatedComponents;
    }
    this.gradingFormResponses = JSON.parse(this.data.get("responses"));
    this.gradingFormSaveUrl =
      document.location.origin + this.data.get("formUpdatePath") + ".json";
    // renderer
    this.gradingForm = await Formio.createForm(
      this.gradingFormElem,
      this.gradingFormSchema,
      {
        noAlerts: true,
      }
    );
    this.gradingForm.submission = this.gradingFormResponses;
    this.formSaveDraftStatus = false;
    const displayImagesForQuestions = (
      formComponents,
      gradingFormResponses,
      ImagesArray
    ) => {
      // Split the string into an array of URLs using a comma as the separator
      const imagesArray = ImagesArray.split("<==>").map((url) => url.trim());
      // Iterate through the form components
      FormioUtils.eachComponent(formComponents, (component) => {
        // Check if the component key is in gradingFormResponses
        if (component.key in gradingFormResponses.data) {
          const questionKey = component.key;
          const verified_key = questionKey + "_sdv_verified";
          const comments_key = questionKey + "_sdv_comments";
          if (gradingFormResponses.sdv) {
            if (verified_key in gradingFormResponses.sdv) {
              const check_sdv = gradingFormResponses.sdv[verified_key];
              const sdv_comments = gradingFormResponses.sdv[comments_key];
              this.appendSdvWithQuestionTitles(
                component,
                check_sdv,
                sdv_comments
              );
            }
          }
          if (
            gradingFormResponses &&
            gradingFormResponses.files &&
            questionKey in gradingFormResponses.files
          ) {
            const imageUrls = gradingFormResponses.files[questionKey];
            this.appendImageWithQuestion(component, imageUrls, imagesArray);
          }
        }
      });
    };
    // Call the function with your data
    Formio.createForm(
      this.gradingFormElem,
      "https://examples.form.io/example"
    ).then((form) => {
      this.gradingForm.on("render", () => {
        if (Object.keys(this.gradingFormResponses).length > 0) {
          displayImagesForQuestions(
            this.gradingForm.components,
            this.gradingFormResponses,
            this.data.get("attachments")
          );
        }
      });
      // uncomment the line below to rerender
      this.gradingForm.redraw();
      if (this.gradingForm.disabled) {
        const dropzoneElements = document.getElementsByClassName("dropzone");
        for (const element of dropzoneElements) {
          element.classList.add("hidden");
        }
      }
    });
    await this.checkifAllImagesUploaded();
    // Initialize shared modules
    this.baseFile = new BaseFileModule(this.element);
    this.formState = new FormStateModule(
      this.gradingForm,
      this.formTarget,
      this.hasSubmitTarget ? this.submitTarget : null,
      this.hasUpdateTarget ? this.updateTarget : null
    );
    this.notifications = new NotificationModule(
      this.hasLoadingImageTarget ? this.loadingImageTarget : null
    );

    this.formSubmissionModule = new FormSubmissionModule({
      formSaveUrl:
        document.location.origin + this.data.get("formUpdatePath") + ".json",
      formVersionId: this.data.get("versionId"),
      formUserId: this.data.get("userId"),
      formStackId: this.data.has("stackId") ? this.data.get("stackId") : null,
      notifications: this.notifications,
      stackIsFinalUrl: "",
      images: this.gradingImages,
    });
    this.cornerstoneGradingModule = new CornerstoneGradingModule({
      formSaveUrl:
        document.location.origin + this.data.get("updatePath") + ".json",
      formUserId: this.data.get("userId"),
      formStackId: this.data.has("stackId") ? this.data.get("stackId") : null,
      annotation: this.annotation,
      element: this.viewerTarget,
      imageTag: this.imageTagTarget,
      url: this.url,
      probCount: this.data.get("countAnnotation"),
      method: this.data.get("mode") == "update" ? "PATCH" : "POST",
      gradingForm: this.gradingForm,
      releaseEye: this.releaseEye,
      notifications: this.notifications,
    });
    // Set the form in modules
    this.notifications.hideLoader();
    this.baseFile.setForm(this.gradingForm);
    if (this.gradingFormAgreementElem.checked) {
      this.formState.disableForm();
    } else {
      this.formState.enableForm();
    }

    window.addEventListener("successFileUpload", (event) => {
      this.handleGlobalFileUpload(this.gradingFormElem, event);
    });
  }

  handleGlobalFileUpload(gradingFormElem, event) {
    this.notifications.showLoader();
    const { fileName, questionName, file } = event.detail;
    // Perform actions with the file, e.g., attach it to the form or preview it
    this.processReceivedFile(file, questionName, gradingFormElem);
  }

  processReceivedFile(file, questionName, form) {
    if (!file) {
      this.notifications.warning(
        "No file selected. Please select an image file to proceed."
      );
      this.notifications.hideLoader(); // Hide loader if it was shown before
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result.split(",")[1]; // Extracting base64 image data
      const inputJSON = { pdf: base64Image };
      const url = "https://dev-heyex-sdar-api.apps.eyesol.net/ocr/";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputJSON),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Server returned an error: HTTP status ${response.status}`
            );
          }
          return response.json();
        })
        .then((data) => {
          // Ensure data has the expected structure
          if (!data || typeof data !== "object") {
            throw new Error(
              "Unexpected response format from the server. Please contact support."
            );
          }
          // Update the form with received data
          console.log(data);
          this.updateFormWithData(form, data);
          this.notifications.success("File processed successfully!");
          this.notifications.hideLoader(); // Hide loader after success
        })
        .catch((error) => {
          console.error("File processing error:", error);
          this.notifications.warning(
            `An error occurred while processing the file. ${
              error.message || "Please try again."
            } If the issue persists, take a screenshot and contact support.`
          );
          this.notifications.hideLoader(); // Hide loader if an error occurs
        });
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      this.notifications.warning(
        "Unable to read the file. Please ensure it is a valid image file and try again."
      );
      this.notifications.hideLoader(); // Hide loader if reading the file fails
    };

    try {
      reader.readAsDataURL(file); // Start reading the file as DataURL
    } catch (error) {
      console.error("Unexpected error during file reading:", error);
      this.notifications.warning(
        "An unexpected error occurred while reading the file. Please ensure the file format is correct and try again. If the problem persists, take a screenshot and contact support."
      );
      this.notifications.hideLoader(); // Hide loader if an unexpected error occurs
    }
  }

  updateFormWithData(form, data) {
    const { laterality } = data.data.patient_details; // Extract "OS", "OD", or "OU"
    const inputs = document.querySelectorAll("[data-screenshot]");

    inputs.forEach((component) => {
      const [key, side] = component.getAttribute("data-screenshot").split("-");
      let value = null;

      if (laterality === "OU" && (side === "OS" || side === "OD")) {
        // Handle OU case: Assign separate values for OS and OD
        value =
          key in data.data[side]?.etdrs_volume
            ? data.data[side].etdrs_volume[key]
            : data.data[side]?.etdrs_thickness?.[key];
      } else if (side === laterality) {
        // Handle single laterality: OS or OD
        value =
          key in data.data[laterality]?.etdrs_volume
            ? data.data[laterality].etdrs_volume[key]
            : data.data[laterality]?.etdrs_thickness?.[key];
      }

      if (value !== null && value !== "N/A") {
        component.value = value;
        component.setAttribute("value", value);
        const event = new Event("input", { bubbles: true });
        component.dispatchEvent(event);
      } else {
        console.log("here abid");
        component.setAttribute("value", "9999");
        const event = new Event("input", { bubbles: true });
        component.dispatchEvent(event);
      }
    });

    this.notifications.hideLoader();
  }

  checkifAllImagesUploaded() {
    this.gradingForm.on("change", () => {
      const qcImagesElements = document.querySelectorAll(".qc_images");
      this.dataQuestionArray = Array.from(qcImagesElements).map((element) =>
        element.getAttribute("data-question")
      );
    });
  }

  calculateAngle() {
    this.cornerstoneGradingModule.calculateAngle(
      this.apiUrl,
      this.data.get("apiKey")
    );
  }

  appendSdvWithQuestionTitles(component, check_sdv, sdv_comments) {
    if (check_sdv === "true") {
      // Create a span element for the SVG icon with the tooltip
      const svgDive = document.createElement("span");
      svgDive.classList.add("has-tooltip");
      svgDive.innerHTML = `
          <?xml version="1.0" encoding="iso-8859-1"?>
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg fill="#007BFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="16px" height="16px" viewBox="0 0 509.604 509.604"
                xml:space="preserve" style ="margin-top:3px">
                <g>
                    <g>
                        <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                            c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                            c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                            c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                            c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                            c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                            c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                            c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                            c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                            C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                            c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                            l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                            c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                            l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
                    </g>
                </g>
            </svg>     
            <span class="tooltip bottom-2 w-min">${sdv_comments}</span>
          `;
      // Get the label element within component.element
      const labelElement = component.element.querySelector("label");
      labelElement.classList.add("flex", "gap-2");
      // Concatenate component.label and svgDive and set it as the innerHTML of labelElement
      labelElement.innerHTML = component.label + svgDive.outerHTML;
      // Optionally, you can also console.log the updated label
      console.log(labelElement.innerHTML);
    }
  }

  appendImageWithQuestion(component, fileUrls, filesArray) {
    const addedFiles = new Set();
    const container = document.createElement("div");
    container.classList.add("flex", "mt-1");

    const commonAttributes = {
      width: "100",
      height: "100",
      alt: "Attachment Image",
      style: "cursor: pointer;",
    };

    for (const fileUrl of fileUrls) {
      for (let i = 0; i < filesArray.length; i++) {
        const attachment = filesArray[i].split("<!==!>");
        let fileName = "";
        if (attachment[1]) {
          // Check if attachment[1] exists
          fileName = attachment[1].split("/").pop();
        } else {
          return;
        }
        const decodedFileName = decodeURIComponent(fileName);

        if (fileUrl === decodedFileName && !addedFiles.has(decodedFileName)) {
          const fileExtension = fileName.split(".").pop().toLowerCase();
          const deleteIcon = this.createDeleteIcon(
            decodedFileName,
            component.key,
            attachment[1],
            attachment[0],
            this.gradingForm._disabled
          );

          if (fileExtension === "pdf") {
            const pdfIconElement = document.createElement("img");
            this.setAttributes(pdfIconElement, {
              src: this.pdfIcon,
              ...commonAttributes,
            });
            const pdfLink = document.createElement("a");
            this.setAttributes(pdfLink, {
              href: attachment[1],
              target: "_blank",
            });
            pdfLink.appendChild(pdfIconElement);
            const fileContainer = document.createElement("div");
            fileContainer.classList.add("file-container");
            fileContainer.appendChild(pdfLink);
            fileContainer.appendChild(deleteIcon);
            container.appendChild(fileContainer);
          } else {
            const imageElement = document.createElement("img");
            this.setAttributes(imageElement, {
              src: attachment[1],
              ...commonAttributes,
            });

            const imageContainer = document.createElement("div");
            imageContainer.classList.add("image-container");
            imageContainer.appendChild(imageElement);
            imageContainer.appendChild(deleteIcon);

            imageElement.addEventListener("click", function () {
              const lightboxImage = document.getElementById("lightbox-image");
              lightboxImage.src = attachment[1];
              const lightboxContainer =
                document.getElementById("lightbox-container");
              lightboxContainer.style.display = "block";
            });

            const lightboxContainer =
              document.getElementById("lightbox-container");
            lightboxContainer.addEventListener("click", function () {
              lightboxContainer.style.display = "none";
            });

            container.appendChild(imageContainer);
          }
          addedFiles.add(decodedFileName);
        }
      }
    }

    if (container.innerHTML.trim() !== "") {
      component.element.appendChild(container);
    }
  }

  // create delete icon to show that with the SDV files or screenshots
  createDeleteIcon(
    decodedFileName,
    componentKey,
    attachmentUrl,
    attachmentId,
    gradingFormDisabled
  ) {
    const deleteIcon = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    const attributes = {
      xmlns: "http://www.w3.org/2000/svg",
      width: "20",
      height: "20",
      viewBox: "0 0 24 24",
      "data-action": "click->cornerstone-grade#deleteSDVImage",
      "data-filename": decodedFileName,
      "data-key": componentKey,
      "data-url": attachmentUrl,
      "data-id": attachmentId,
    };

    this.setAttributes(deleteIcon, attributes);

    if (!gradingFormDisabled) {
      deleteIcon.innerHTML = `
                <title>Delete Attachment</title>
                <path fill="#FF0000" d="M18 4h-4.18l-1.82-2H10L8.82 4H4v2h16V4zM6.36 8h11.28l1.23 10.96l.01.04V20H4v-1.96l.01-.04L5.64 8zM8 18h8v-2H8v2zm0-4h8v-6H8v6z"/>
            `;
    }

    return deleteIcon;
  }
  // To delete the images with Questions for SDV (Source documents verifications)
  deleteSDVImage(e) {
    e.preventDefault();
    const parentDiv = event.target.closest(".image-container, .file-container");
    const confirmation = confirm("Are you sure you want to delete this file?");
    if (confirmation) {
      const fileName = e.currentTarget.getAttribute("data-filename");
      const fileKey = e.currentTarget.getAttribute("data-key");
      const fileUrl = e.currentTarget.getAttribute("data-url");
      const blob_id = e.currentTarget.getAttribute("data-id");
      fetch(`/delete_image`, {
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": document.head
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        body: JSON.stringify({
          image_url: fileName,
          stack_id: this.gradingFormStackId,
          image_array: fileKey,
          url: fileUrl,
          blob_id: blob_id,
        }),
      })
        .then((response) => {
          if (response.ok) {
            parentDiv.remove();
            location.reload();
          } else {
            console.error("Failed to delete image");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  // set attribute of icon
  setAttributes(element, attributes) {
    Object.entries(attributes).forEach(([key, value]) =>
      element.setAttribute(key, value)
    );
  }

  toggleTags(e) {
    this.imageTag.classList.toggle("hidden");
    e.preventDefault();
  }

  get url() {
    return this.data.get("url");
  }

  // formio functions
  formSaveDraft(e) {
    this.handleFormCommitAndSubmit(e, "initiated", true, true);
  }

  formCommit(e) {
    if (this.gradingFormAgreementElem.checked) {
      this.handleFormCommitAndSubmit(e, "completed", true);
    } else {
      alert("You must agree to terms");
    }
    e.preventDefault();
  }

  formUpdate(e) {
    this.handleFormCommitAndSubmit(e, "editing");
  }

  handleFormCommitAndSubmit(
    e,
    status,
    shouldSubmit = false,
    draftStatus = false
  ) {
    e.preventDefault();

    // Validate the form before submission
    this.gradingForm
      .submit()
      .then((submission) => {
        // Check if images are uploaded!
        if (status == "completed") {
          const uploaded = this.formSubmissionModule.checkIfAllImagesUploaded(
            submission,
            this.dataQuestionArray
          );

          if (!uploaded) {
            // Reject the promise to prevent further execution
            this.notifications.error("Images not uploaded");
            return;
          }
        }
        // Assuming you have this.gradingImages defined in your controller
        this.formSubmissionModule.collectImagesFromSelector(
          ".attached_images",
          this.gradingImages
        );
        this.defaultSaveAnnotation();
        // Handle the form submission after commit is successful
        return this.formSubmissionModule.submitForm(
          submission,
          this.data.get("mode") == "update" ? "PATCH" : "POST",
          null,
          null,
          draftStatus,
          "grading_form",
          status
        );
      })
      .catch((error) => {
        console.error(error);
        // You can also handle any additional error logic here if needed
      });
  }

  // Save Annotations
  submitGrading(e) {
    // Call the submitGrading method from the grading module with the HTTP method
    this.cornerstoneGradingModule.submitGrading(e);
  }

  defaultSaveAnnotation() {
    // Call defaultSaveAnnotation from the grading module
    this.cornerstoneGradingModule.defaultSaveAnnotation();
  }

  successAlert() {
    window.dispatchEvent(new CustomEvent("successAlert"));
  }

  errorAlert() {
    window.dispatchEvent(new CustomEvent("errorAlert"));
  }

  toggleSubmitArea() {
    var staticPanel = document.getElementById("expend_colapse");
    if (this.questionAreaTarget.classList.contains("hidden")) {
      this.questionAreaTarget.classList.remove("hidden");
      staticPanel.classList.add("fa-minus-square-o");
      staticPanel.classList.remove("fa-plus-square-o");
    } else {
      this.questionAreaTarget.classList.add("hidden");
      staticPanel.classList.add("fa-plus-square-o");
      staticPanel.classList.remove("fa-minus-square-o");
    }
  }

  fetchPreviousVisitAnswers(event) {
    event.preventDefault();
    const method = "POST";
    fetch(this.gradingPreviousUrl, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: method,
      body: JSON.stringify({
        stack_id: this.gradingFormStackId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "Found") {
          if (!data || typeof data !== "object") {
            throw new Error("Invalid JSON data received");
          }
          const previousGradingFormData = data.responses.concat(
            data.history_reponse
          );
          const userIDs = data.user_names;
          const previousVisitLabel = data.previous_visit_label;
          const previousVisitDate = data.previous_date;
          const formStatuses = data.status;
          const visitDates = data.history_visit_dates;
          const visitIds = data.history_visits_ids;
          const previousStatusArray = Array.isArray(data.previous_status)
            ? data.previous_status
            : [];
          if (data.responses.length !== userIDs.length) {
            console.error(
              "Length mismatch between previousGradingFormData and userIDs"
            );
          } else {
            if (previousGradingFormData.length > 0) {
              const firstPreviousStatus =
                previousStatusArray.length > 0 ? previousStatusArray[0] : null;
              this.appendPreviousVisitAnsToGradingForm(
                previousGradingFormData[0],
                firstPreviousStatus,
                previousVisitLabel,
                previousVisitDate
              );
            }

            for (let i = 1; i < previousGradingFormData.length; i++) {
              const visitDate =
                visitDates.length > i - 1 ? visitDates[i - 1] : null;
              const visitId = visitIds.length > i - 1 ? visitIds[i - 1] : null;
              const formStatus =
                formStatuses.length > i - 1 ? formStatuses[i - 1] : null;
              this.appendPreviousVisitAnsToGradingForm(
                previousGradingFormData[i],
                null,
                previousVisitLabel,
                previousVisitDate,
                formStatus,
                visitDate,
                visitId,
                true
              );
            }
          }

          document
            .getElementById("viewPreviousAnsButton")
            .classList.add("hidden");
          document
            .getElementById("hidePreviousAnsButton")
            .classList.remove("hidden");
        } else if (data.message == "No previous visit Found") {
          alert("No previous visit Available");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  hidePreviousAns(event) {
    event.preventDefault();

    // Remove the appended data by removing the .grader-answers-div elements
    const graderAnswersDivs = document.querySelectorAll(".adj-answers-div");
    graderAnswersDivs.forEach((div) => {
      div.remove();
    });

    // Show the "View Previous Ans" button
    const viewPreviousAnsButton = document.getElementById(
      "viewPreviousAnsButton"
    );
    viewPreviousAnsButton.classList.remove("hidden");

    // Hide the "Hide Previous Ans" button
    const hidePreviousAnsButton = document.getElementById(
      "hidePreviousAnsButton"
    );
    hidePreviousAnsButton.classList.add("hidden");
  }

  appendPreviousVisitAnsToGradingForm(
    previousGradingFormData,
    previousStatus,
    previousVisitLabel,
    previousVisitDate,
    formStatus,
    visitDate,
    visitId,
    isSubsequentCall = false
  ) {
    FormioUtils.eachComponent(this.gradingForm.components, (c) => {
      if (
        c &&
        c.component &&
        c.element &&
        !c.element.classList.contains("formio-hidden")
      ) {
        let graderAnswersDiv = c.element.querySelector(".adj-answers-div");
        if (!graderAnswersDiv) {
          graderAnswersDiv = document.createElement("div");
          graderAnswersDiv.classList.add("adj-answers-div");
          c.element.appendChild(graderAnswersDiv);
        }

        const questionKey = c.component.key;

        if (previousGradingFormData.data.hasOwnProperty(questionKey)) {
          const label =
            this.getLabelFromSchema(
              this.gradingFormSchema,
              questionKey,
              previousGradingFormData.data[questionKey]
            ) || "nil";
          const timestamp = new Date(previousVisitDate);
          const formattedDate = `${timestamp.getFullYear()}-${String(
            timestamp.getMonth() + 1
          ).padStart(2, "0")}-${String(timestamp.getDate()).padStart(2, "0")}`;
          let currentFormStatus = formStatus;
          if (currentFormStatus === "completed") {
            currentFormStatus = "[a]";
          } else if (currentFormStatus === "editing") {
            currentFormStatus = "[e]";
          } else {
            currentFormStatus = "[p]";
          }
          let previousFormStatus = previousStatus;
          if (previousFormStatus === "completed") {
            previousFormStatus = "[a]";
          } else if (previousFormStatus === "editing") {
            previousFormStatus = "[e]";
          } else {
            previousFormStatus = "[p]";
          }
          const answerTextSpan = document.createElement("span");
          answerTextSpan.classList.add("has-tooltip");
          answerTextSpan.innerHTML = !isSubsequentCall
            ? `
                        ${previousVisitLabel}: ${label} (${formattedDate}) ${previousFormStatus}
                    `
            : "";

          if (!isSubsequentCall) {
            // Append the answerTextSpan to graderAnswersDiv
            graderAnswersDiv.appendChild(answerTextSpan);

            // Add the 'has-tooltip' class to the answerTextSpan
            answerTextSpan.classList.add("has-tooltip");

            // Create the tooltip span
            const tooltipSpan = document.createElement("span");
            tooltipSpan.classList.add("tooltip");

            // Append the tooltip span to the answerTextSpan
            answerTextSpan.appendChild(tooltipSpan);
          } else {
            // Find the first element with the class 'has-tooltip'
            const firstAnswerSpan =
              graderAnswersDiv.querySelector(".has-tooltip");

            if (firstAnswerSpan) {
              const tooltipSpan = firstAnswerSpan.querySelector(".tooltip");

              if (tooltipSpan) {
                // Safely append new content to the tooltip
                tooltipSpan.innerHTML += `${visitId}: ${label} (${visitDate}) ${currentFormStatus}<br>`;
              } else {
                console.error("Tooltip span not found inside has-tooltip span");
              }
            } else {
              // Optional: Create a new span if not found
              const newAnswerSpan = document.createElement("span");
              newAnswerSpan.classList.add("has-tooltip");
              graderAnswersDiv.appendChild(newAnswerSpan);

              const newTooltipSpan = document.createElement("span");
              newTooltipSpan.classList.add("tooltip");
              newAnswerSpan.appendChild(newTooltipSpan);
              // Now update the new tooltip span with the content
              newTooltipSpan.innerHTML += `${visitId}: ${label} (${visitDate}) ${currentFormStatus}<br>`;
            }
          }
        }
      }
    });
  }

  getLabelFromSchema(schema, questionKey, questionValue) {
    for (const component of schema.components) {
      if (component.components) {
        // If the component has nested questions, iterate through them
        for (const question of component.components) {
          const targetKey = question["key"];
          const checkboxes = [];
          if (
            question.values &&
            question["type"] === "radio" &&
            targetKey === questionKey
          ) {
            for (const item of question.values) {
              if (item["value"] == questionValue) {
                return item["label"];
              }
            }
          } else if (
            question.values &&
            question["type"] === "selectboxes" &&
            targetKey === questionKey
          ) {
            for (var key in questionValue) {
              if (questionValue[key]) {
                // Use the current key to access the corresponding element in question.values
                checkboxes.push(question.values[key - 1]["label"]);
              }
            }
            return checkboxes;
          }
        }
      }
    }
    return questionValue;
  }

  arrowAnnotationTool(e) {
    this.cornerstoneGradingModule.activateTool("ArrowAnnotateTool");
    e.preventDefault();
  }

  changeImage(e, elem) {
    let target = e ? e.target : elem;
    this.cornerstoneGradingModule.imageLoader(target.dataset.url);

    if (e) {
      this.currentImage = target.dataset.idx;
      console.log(`current index selected ${this.currentImage}`);
    }

    this.imageTag.innerHTML = "";
  }
  // Cornerstone tools

  probeTool = (e) => {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("Probe");
  };

  levelsTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("Wwwc");
  }

  panTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("Pan");
  }

  lengthTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("Length");
  }

  angleTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("Angle");
  }

  rectangleTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("RectangleRoi");
  }

  freehandTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("FreehandRoi");
  }

  ellipseTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("EllipticalRoi");
  }

  eraserTool(e) {
    e.preventDefault();
    this.cornerstoneGradingModule.activateTool("Eraser");
  }

  labelTool(e) {
    this.cornerstoneGradingModule.labelTool(e);
  }
}
