import Swal from "sweetalert2";

export default class NotificationModule {
  constructor(loadingImageTarget = null) {
    this.loadingImageTarget = loadingImageTarget;
  }

  success(message = "Updated!") {
    Swal.fire({
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 2000,
    });
  }

  error(message = "An Error Occurred!") {
    Swal.fire({
      icon: "warning",
      title: message,
      showConfirmButton: false,
      timer: 3000,
    });
  }

  warning(message = "Are you sure?", confirmButtonText = "OK") {
    return Swal.fire({
      title: message,
      icon: "warning",
      confirmButtonText: confirmButtonText,
    });
  }

  confirmAction(
    message = "Are you sure?",
    description = "Do you want to proceed?",
    confirmButtonText = "Yes",
    cancelButtonText = "No"
  ) {
    return Swal.fire({
      title: message,
      text: description,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      reverseButtons: true,
    });
  }

  showLoader() {
    if (this.loadingImageTarget) {
      this.loadingImageTarget.classList.add("visible");
    }
  }

  hideLoader() {
    if (this.loadingImageTarget) {
      this.loadingImageTarget.classList.remove("visible");
    }
  }
}
