export default class FormSubmissionModule {
    constructor({ formSaveUrl, fileType, formVersionId, formUserId, formStackId = null, images = [], notifications, stackIsFinalUrl = null, qcForm = null }) {
        this.fileType = fileType;
        this.formSaveUrl = formSaveUrl;
        this.formVersionId = formVersionId;
        this.formUserId = formUserId;
        this.formStackId = formStackId;
        this.images = images;
        this.notifications = notifications;
        this.stackIsFinalUrl = stackIsFinalUrl;
        this.qcForm = qcForm;
    }

    collectImagesFromSelector(selector, imageArray) {
        document.querySelectorAll(selector).forEach((input) => {
            if (input.value !== '' && !imageArray.includes(input.value)) {
                this.images.push(input.value);
            }
        });
    }

    async submitForm(submission, method = 'PATCH', successCallback = null, errorCallback = null, draftStatus = null, formType = 'qc_form', formStatus = null) {

        try {
            // Common parameters
            const commonParams = {
                responses: submission,
                form_version_id: this.formVersionId,
                user_id: this.formUserId,
                images: this.images
            };

            // Specific parameters for different form types
            let specificParams = {};

            switch (formType) {
                case 'grading_form':
                    specificParams = {
                        grading_form: {
                            ...commonParams,
                            disclaimer: submission.isAgreed,
                            status: formStatus,
                            stack_id: this.formStackId
                        }
                    };
                    break;
                case 'adjudication':
                    specificParams = {
                        adjudication: {
                            ...commonParams,
                            status: formStatus
                        }
                    }
                    break;
                case 'other_grading_form':
                    specificParams = {
                        other_grading_form: {
                            ...commonParams,
                            disclaimer: submission.isAgreed,
                            status: formStatus,
                            stack_id: this.formStackId
                        }
                    };
                    break;
                case 'other_adjudication':
                    specificParams = {
                        other_adjudication: {
                            ...commonParams,
                            status: formStatus
                        }
                    }
                    break;
                case 'eligibility_form':
                    specificParams = {
                        eligibility_form: {
                            ...commonParams,
                            status: formStatus,
                            stack_id: this.formStackId
                        }
                    }
                    break;
                default:
                    specificParams = commonParams;  // Default to qc_form
                    break;
            }

            const response = await fetch(this.formSaveUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: method,
                body: JSON.stringify(specificParams)
            });

            this.notifications.hideLoader();
            if (response.status === 200) {
                (formStatus == 'completed') ? this.notifications.success('Form submitted successfully!') : null;
                if (typeof successCallback === 'function') successCallback();
                location.reload();
            } else {
                this.notifications.error();
                if (typeof errorCallback === 'function') errorCallback('Something went wrong with form submissions!');
            }
        } catch (error) {
            this.notifications.error();
            if (typeof errorCallback === 'function') errorCallback('Something went wrong with form submissions!');
        }
    }


    async formCommit(e, isFinal, buttonSelector, releaseEyeTarget, gradabilityTarget, releaseComments, releaseReason, qcFormImageViewer, checkImagesLiterality, checkLiteralityWithReleaseEye, formStatus = null, draftStatus = null) {
        e.preventDefault();
        const button = document.querySelector(buttonSelector);
        this.notifications.showLoader();
        const release_eye = releaseEyeTarget.querySelector('input[name="release_eye"]:checked');
        const gradable = gradabilityTarget.querySelector('input[name="gradable"]:checked');

        if (gradable === null && formStatus == 'completed') {
            alert('Please select gradability option!');
            this.notifications.hideLoader();
            return false;
        }

        if (release_eye === null && formStatus == 'completed') {
            alert('Please release eye with acknowledgement!');
            this.notifications.hideLoader();
            return false;
        }
        const fileTypesArray = this.fileType.split(',');
        const allFilesArePDFs = fileTypesArray.every((type) => type.toLowerCase() === 'pdf');

        if (
            release_eye.value !== "NA" &&
            !checkImagesLiterality() &&
            qcFormImageViewer === 'true' &&
            formStatus == 'completed'
        ) {
            if (!allFilesArePDFs) {
                alert("No image selected for literality.");
                this.notifications.hideLoader();
                button.classList.remove('hidden');
                return;
            }
        }

        if ((release_eye.value === "OD" || release_eye.value === "OS") && qcFormImageViewer === 'true' && !checkLiteralityWithReleaseEye() && formStatus == 'completed') {
            alert('Release eye must match any of the image literality!');
            this.notifications.hideLoader();
            button.classList.remove('hidden');
            return;
        }
        if (isFinal || draftStatus) {
            try {
                const response = await fetch(this.stackIsFinalUrl, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        status: formStatus,
                        gradable: gradable.value,
                        release_eye: release_eye.value,
                        release_comment: releaseComments && releaseComments.value ? releaseComments.value : '',
                        release_reason: releaseReason && releaseReason.value ? releaseReason.value : '',
                        user_id: this.formUserId
                    })
                });
                this.notifications.hideLoader();
                button.classList.remove('hidden');
                if (formStatus == 'editing') {
                    location.reload();
                }
                return true;
            } catch (error) {
                // Revert stack status if form submission fails
                await fetch(this.stackIsFinalUrl, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        status: 'initiated',
                        gradable: '',
                        release_eye: '',
                        release_comment: '',
                        release_reason: '',
                        user_id: this.formUserId
                    })
                });

                this.notifications.hideLoader();
                button.classList.remove('hidden');
                console.error('Error during commit:', error);
            }
        } else if (!draftStatus && !isFinal) {
            alert('You must agree to terms');
            this.notifications.hideLoader();
            return false;
        }
    }

    checkIfAllImagesUploaded(submission, dataQuestionArray) {
        const allImagesAreUploaded = true;
        const uploadedFilesObj = submission.files;

        if (uploadedFilesObj && dataQuestionArray.length > 0) {
            for (const key of dataQuestionArray) {
                if (!uploadedFilesObj.hasOwnProperty(key)) {
                    this.uploadImageForQuestion(key);
                    this.notifications.hideLoader();
                    return false; // Exit early if any image is missing
                }
            }
        } else if (!uploadedFilesObj && dataQuestionArray.length > 0) {
            this.notifications.error('Please Upload screenshots!');
            this.notifications.hideLoader();
            return false;
        }

        return allImagesAreUploaded;
    }

    uploadImageForQuestion(key) {
        Swal.fire({
            icon: 'warning',
            title: 'Please Upload screenshots for ' + key,
            showConfirmButton: true
        });
    }
}
