import { Controller } from "stimulus";
import { Formio } from "formiojs";
import FormioUtils from "formiojs/utils";
import BaseFileModule from "./shared_modules/base_file_module.js";
import Hammer from "hammerjs";
import * as cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneMath from "cornerstone-math";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import utils from "formiojs/utils";
import FormStateModule from "./shared_modules/form_state_module.js";
import NotificationModule from "./shared_modules/notification_module.js";
import FormSubmissionModule from "./shared_modules/form_submission_module.js";

const {
  PanTool,
  WwwcTool,
  ZoomMouseWheelTool,
  LengthTool,
  ArrowAnnotateTool,
  AngleTool,
  EllipticalRoiTool,
  EraserTool,
  RectangleRoiTool,
  FreehandRoiTool,
} = cornerstoneTools;
const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;
// const RIGHT_CLICK = 2;
export default class extends Controller {
  static targets = [
    "viewer",
    "url",
    "form",
    "path",
    "agreement",
    "users",
    "regradingForm",
    "regraders",
    "menu",
    "menuIcon",
    "formSection",
    "cornerstone",
    "cornerstoneIcon",
    "questionArea",
    "submit",
    "update",
    "loadingImage",
  ];
  async initialize() {
    // Define Targets
    this.pdfIcon = this.data.get("pdf-icon");
    const elem = this.hasViewerTarget ? this.viewerTarget : null;
    this.gradingFormElem = this.formTarget;
    this.gradingFormAgreementElem = this.agreementTarget;
    this.annotationUsersElem = this.usersTarget;
    this.regradersElems = this.regradersTargets;
    this.regradingFormElem = this.regradingFormTarget;
    this.imagesMenu = this.menuTarget;
    this.formSection = this.formSectionTarget;
    this.cornerstoneViewer = this.cornerstoneTarget;
    const imageKeys = this.data.get("imageKeys");
    this.adjImages = imageKeys
      ? imageKeys.split(",").map((key) => key.trim())
      : [];
    this.viewerElement = elem;
    this.gradingSaveUrl =
      document.location.origin + this.data.get("updatePath") + ".json";
    this.graderNameUrl =
      document.location.origin + this.data.get("graderNamePath") + ".json";
    this.regradingUrl =
      document.location.origin + this.data.get("regradePath") + ".json";
    this.gradingPreviousUrl =
      document.location.origin + this.data.get("previousAns") + ".json";
    this.gradingFormStackId = this.data.get("stackId");
    this.releaseEye = this.data.get("releaseEye");
    this.images = [];
    this.readImages();
    this.currentImage = 0;
    this.gradingFormVersionId = this.data.get("versionId");
    this.viewerStatus = this.data.get("viewerStatus");
    this.formSaveDraftStatus = false;
    // load previously saved grading data if available
    this.annotation = JSON.parse(this.data.get("annotation"));
    this.annotationIsLoadedOnce = {};
    this.graderAnnotations = JSON.parse(this.data.get("graderAnnotations"));
    // Responses from Graders
    this.graderResponses = JSON.parse(this.data.get("graderResponses"));
    // get all files of graders
    this.imagesUrls = {};
    // Iterate through each GradingForm object in your collection
    this.graderResponses.forEach((gradingForm) => {
      // Access the responses.files data from the GradingForm object
      const responsesFiles = gradingForm.responses.files;
      for (const fileKey in responsesFiles) {
        if (responsesFiles.hasOwnProperty(fileKey)) {
          const fileUrls = responsesFiles[fileKey];
          if (this.imagesUrls[fileKey]) {
            // If the file name is already a key in this.imagesUrls, append the new URLs to the existing array
            this.imagesUrls[fileKey] =
              this.imagesUrls[fileKey].concat(fileUrls);
          } else {
            // If the file name is not already a key, create a new key with an array containing the URLs
            this.imagesUrls[fileKey] = fileUrls;
          }
        }
      }
    });
    // end
    this.graderCount = this.data.get("graderRequire");
    this.StudyEye = this.data.get("studyEye");
    this.CheckEye = this.data.get("checkEye");
    this.gradingFormSchema = JSON.parse(this.data.get("gradingForm"));
    if (this.CheckEye == "Study Eye") {
      const updatedComponents = [];
      this.gradingFormSchema.components.forEach((multiple_trials) => {
        if (
          multiple_trials["key"].slice(-2).toLowerCase() == this.StudyEye ||
          this.StudyEye == "ou" ||
          (multiple_trials["key"].slice(-2).toLowerCase() != "od" &&
            multiple_trials["key"].slice(-2).toLowerCase() != "os")
        ) {
          updatedComponents.push(multiple_trials);
        }
      });
      this.gradingFormSchema["components"] = updatedComponents;
    } else {
      const updatedComponents = [];
      this.gradingFormSchema.components.forEach((multiple_trials) => {
        if (
          multiple_trials["key"].slice(-2).toLowerCase() == this.releaseEye ||
          this.releaseEye == "ou" ||
          (multiple_trials["key"].slice(-2).toLowerCase() != "od" &&
            multiple_trials["key"].slice(-2).toLowerCase() != "os")
        ) {
          updatedComponents.push(multiple_trials);
        }
      });
      this.gradingFormSchema["components"] = updatedComponents;
    }
    this.gradingFormResponses = JSON.parse(this.data.get("responses"));
    this.gradingFormSaveUrl =
      document.location.origin + this.data.get("formUpdatePath") + ".json";
    // renderer
    this.gradingForm = await Formio.createForm(
      this.gradingFormElem,
      this.gradingFormSchema,
      {
        noAlerts: true,
      }
    );
    // set the draft responses if any
    this.gradingForm.submission = this.gradingFormResponses;
    //  Corner Stone Setup
    if (this.viewerStatus == "true") {
      cornerstoneWebImageLoader.external.cornerstone = cornerstone;
      cornerstoneTools.external.cornerstone = cornerstone;
      cornerstoneTools.external.Hammer = Hammer;
      cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
      cornerstoneTools.init({
        mouseEnabled: true,
        touchEnabled: true,
        globalToolSyncEnabled: true,
        showSVGCursors: true,
      });
      cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
        (element, imageId, error) => {
          console.error(imageId);
          // throw error;
        }
      );
      const imageUrl = document.location.origin + "/" + this.url;
      cornerstone.enable(elem);
      // cornerstone.registerImageLoader = 'dicomweb';
      cornerstone.loadAndCacheImage(imageUrl).then((image) => {
        this.loadExistingAnnotationsForImage(imageUrl);
        cornerstone.displayImage(elem, image);
        // Activate Pan Tool on Middle Click Drag
        cornerstoneTools.addTool(PanTool);
        cornerstoneTools.setToolActive("Pan", {
          mouseButtonMask: MIDDLE_CLICK,
        });
        // Activate WWWc tool on Left Click Drag
        cornerstoneTools.addTool(WwwcTool);
        cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: LEFT_CLICK });
        // Activate Zoom Mouse Wheel Tool
        cornerstoneTools.addTool(ZoomMouseWheelTool, {
          // Optional configuration
          configuration: {
            invert: false,
            preventZoomOutsideImage: false,
            minScale: 0.01,
            maxScale: 20.0,
          },
        });
        cornerstoneTools.setToolActive("ZoomMouseWheel", {});
        // Add Non Active tools
        cornerstoneTools.addTool(LengthTool);
        cornerstoneTools.addTool(ArrowAnnotateTool);
        cornerstoneTools.addTool(AngleTool);
        cornerstoneTools.addTool(RectangleRoiTool);
        cornerstoneTools.addTool(FreehandRoiTool);
        cornerstoneTools.addTool(EraserTool);
        cornerstoneTools.addTool(EllipticalRoiTool, {
          configuration: {
            drawHandlesOnHover: false,
            hideHandlesIfMoving: true,
            renderDashed: false,
          },
        });
        // register cornerstone tools events
        // https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/events.js
        elem.addEventListener("cornerstonetoolsmeasurementcompleted", (e) => {
          console.log(`------${e.detail.toolName}-----`);
          console.table(e.detail.measurementData);
        });
        this.refreshTools();
      });
      // attach event listeners
      this.viewerElement.addEventListener("cornerstonenewimage", (e) => {
        // console.log(e);
        e.detail.viewport.displayedArea.brhc.x = e.detail.image.width;
        e.detail.viewport.displayedArea.brhc.y = e.detail.image.height;
      });
    }
    // end
    // Initialize shared modules
    this.baseFile = new BaseFileModule(this.element);
    this.formState = new FormStateModule(
      this.gradingForm,
      this.formTarget,
      this.hasSubmitTarget ? this.submitTarget : null,
      this.hasUpdateTarget ? this.updateTarget : null
    );
    this.notifications = new NotificationModule(
      this.hasLoadingImageTarget ? this.loadingImageTarget : null
    );
    this.formSubmissionModule = new FormSubmissionModule({
      formSaveUrl:
        document.location.origin + this.data.get("formUpdatePath") + ".json",
      formVersionId: this.data.get("versionId"),
      formUserId: this.data.get("userId"),
      formStackId: this.data.has("stackId") ? this.data.get("stackId") : null,
      notifications: this.notifications,
      stackIsFinalUrl: "",
      images: this.adjImages,
    });

    this.baseFile.setForm(this.gradingForm);
    await this.renderGradingDataAndImages();
    // Form State

    this.notifications.hideLoader();
    if (this.gradingFormAgreementElem.checked) {
      this.formState.disableForm();
    } else {
      this.formState.enableForm();
    }
    window.addEventListener("successFileUpload", (event) => {
      this.handleGlobalFileUpload(this.gradingFormElem, event);
    });
  }

  handleGlobalFileUpload(gradingFormElem, event) {
    this.notifications.showLoader();
    const { fileName, questionName, file } = event.detail;
    // Perform actions with the file, e.g., attach it to the form or preview it
    this.processReceivedFile(file, questionName, gradingFormElem);
  }

  processReceivedFile(file, questionName, form) {
    if (!file) {
      this.notifications.warning(
        "No file selected. Please select an image file to proceed."
      );
      this.notifications.hideLoader(); // Hide loader if it was shown before
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result.split(",")[1]; // Extracting base64 image data
      const inputJSON = { pdf: base64Image };
      const url = "https://dev-heyex-sdar-api.apps.eyesol.net/ocr/";

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputJSON),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Server returned an error: HTTP status ${response.status}`
            );
          }
          return response.json();
        })
        .then((data) => {
          // Ensure data has the expected structure
          if (!data || typeof data !== "object") {
            throw new Error(
              "Unexpected response format from the server. Please contact support."
            );
          }
          // Update the form with received data
          console.log(data);
          this.updateFormWithData(form, data);
          this.notifications.success("File processed successfully!");
          this.notifications.hideLoader(); // Hide loader after success
        })
        .catch((error) => {
          console.error("File processing error:", error);
          this.notifications.warning(
            `An error occurred while processing the file. ${
              error.message || "Please try again."
            } If the issue persists, take a screenshot and contact support.`
          );
          this.notifications.hideLoader(); // Hide loader if an error occurs
        });
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      this.notifications.warning(
        "Unable to read the file. Please ensure it is a valid image file and try again."
      );
      this.notifications.hideLoader(); // Hide loader if reading the file fails
    };

    try {
      reader.readAsDataURL(file); // Start reading the file as DataURL
    } catch (error) {
      console.error("Unexpected error during file reading:", error);
      this.notifications.warning(
        "An unexpected error occurred while reading the file. Please ensure the file format is correct and try again. If the problem persists, take a screenshot and contact support."
      );
      this.notifications.hideLoader(); // Hide loader if an unexpected error occurs
    }
  }

  updateFormWithData(form, data) {
    const { laterality } = data.data.patient_details; // Extract "OS", "OD", or "OU"
    const inputs = document.querySelectorAll("[data-screenshot]");

    inputs.forEach((component) => {
      const [key, side] = component.getAttribute("data-screenshot").split("-");
      let value = null;

      if (laterality === "OU" && (side === "OS" || side === "OD")) {
        // Handle OU case: Assign separate values for OS and OD
        value =
          key in data.data[side]?.etdrs_volume
            ? data.data[side].etdrs_volume[key]
            : data.data[side]?.etdrs_thickness?.[key];
      } else if (side === laterality) {
        // Handle single laterality: OS or OD
        value =
          key in data.data[laterality]?.etdrs_volume
            ? data.data[laterality].etdrs_volume[key]
            : data.data[laterality]?.etdrs_thickness?.[key];
      }

      if (value !== null && value !== "N/A") {
        component.value = value;
        component.setAttribute("value", value);
        const event = new Event("input", { bubbles: true });
        component.dispatchEvent(event);
      } else {
        component.setAttribute("value", "9999");
        const event = new Event("input", { bubbles: true });
        component.dispatchEvent(event);
      }
    });

    this.notifications.hideLoader();
  }

  renderGradingDataAndImages() {
    Formio.createForm(
      this.gradingFormElem,
      "https://examples.form.io/example"
    ).then((form) => {
      // Attach the callback function to the "render" event
      this.gradingForm.on("render", () => {
        // Call the defined functions
        this.displayGradingAnswerToAdjudication();
        if (Object.keys(this.gradingFormResponses).length > 0) {
          this.displayImagesForQuestions(
            this.gradingForm.components,
            this.gradingFormResponses,
            this.data.get("attachments")
          );
          this.displayImagesForQuestions(
            this.gradingForm.components,
            this.gradingFormResponses,
            this.data.get("gradingAttachments")
          );
        }
        this.highlightQuestionsToAdjudicator(
          this.gradingForm.components,
          this.gradingFormResponses
        );
      });
      // Attach thebfunction to the "change" event
      this.gradingForm.on("change", () => {
        // defined functions
        this.displayGradingAnswerToAdjudication();
        if (Object.keys(this.gradingFormResponses).length > 0) {
          this.displayImagesForQuestions(
            this.gradingForm.components,
            this.gradingFormResponses,
            this.data.get("attachments")
          );
          this.displayImagesForQuestions(
            this.gradingForm.components,
            this.gradingFormResponses,
            this.data.get("gradingAttachments")
          );
        }
        this.highlightQuestionsToAdjudicator(
          this.gradingForm.components,
          this.gradingFormResponses
        );
      });
      // uncomment the line below to rerender
      this.gradingForm.redraw();
      if (this.gradingForm.disabled) {
        const dropzoneElements = document.getElementsByClassName("dropzone");
        for (const element of dropzoneElements) {
          element.classList.add("hidden");
        }
      }
    });
  }

  async displayImagesForQuestions(
    formComponents,
    gradingFormResponses,
    ImagesArray
  ) {
    // Split the string into an array of URLs using a comma as the separator
    const imagesArray = ImagesArray.split("<==>").map((url) => url.trim());
    // Iterate through the form components
    FormioUtils.eachComponent(formComponents, (component) => {
      // Check if the component key is in gradingFormResponses
      if (component.key in gradingFormResponses.data) {
        const questionKey = component.key;
        const verified_key = questionKey + "_sdv_verified";
        const comments_key = questionKey + "_sdv_comments";
        if (gradingFormResponses.sdv) {
          if (
            gradingFormResponses &&
            gradingFormResponses.sdv &&
            verified_key in gradingFormResponses.sdv
          ) {
            const check_sdv = gradingFormResponses.sdv[verified_key];
            const sdv_comments = gradingFormResponses.sdv[comments_key];
            this.appendSdvWithQuestionTitles(
              component,
              check_sdv,
              sdv_comments
            );
          }
        }
        if (
          gradingFormResponses &&
          gradingFormResponses.files &&
          questionKey in gradingFormResponses.files
        ) {
          const imageUrls = gradingFormResponses.files[questionKey];
          this.appendAdjudicationImageWithQuestion(
            component,
            imageUrls,
            imagesArray
          );
        }
        // console.log(questionKey);
      }
      for (const response of this.graderResponses) {
        if (
          "data" in response.responses &&
          component.key in response.responses.data
        ) {
          const questionKey = component.key;
          if (
            this.graderResponses &&
            this.imagesUrls &&
            questionKey in this.imagesUrls
          ) {
            this.appendGradingImageWithQuestion(
              component,
              questionKey,
              this.imagesUrls,
              imagesArray
            );
          }
        }
      }
    });
  }

  highlightQuestionsToAdjudicator() {
    if (!this.gradingFormAgreementElem.checked) {
      FormioUtils.eachComponent(this.gradingForm.components, (c) => {
        if (c && c.component && c.element) {
          const element = c.element;
          if (!element.classList.contains("formio-hidden")) {
            const questionKey = c.component.key;
            const responseValues = this.graderResponses.map(
              (r) => r.responses.data[questionKey]
            );
            if (
              responseValues.length > 1 &&
              !responseValues.every((value) => value === responseValues[0])
            ) {
              // Values are not equal, add red border to the parent div
              const parentDiv = element.closest(".formio-component");
              if (parentDiv) {
                parentDiv.classList.add(
                  "border-dashed",
                  "border-2",
                  "border-red-600",
                  "p-1"
                );
              }
            }
          }
        }
      });
    }
  }

  displayGradingAnswerToAdjudication() {
    if (!this.gradingFormAgreementElem.checked) {
      FormioUtils.eachComponent(this.gradingForm.components, (c) => {
        if (c && c.component && c.element) {
          const element = c.element;
          if (!element.classList.contains("formio-hidden")) {
            // Check if graderAnswersDiv already exists
            let graderAnswersDiv = element.querySelector(".grader-answers-div");
            if (!graderAnswersDiv) {
              graderAnswersDiv = document.createElement("div");
              graderAnswersDiv.classList.add("grader-answers-div");
              element.append(graderAnswersDiv);
            } else {
              return;
            }
            const questionKey = c.component.key;
            let valToCompare = "rand1234";
            let match = true;
            // Check if there are two answers and they are equal
            const answers = this.graderResponses.map(
              (r) => r.responses.data[questionKey]
            );
            if (answers.length === 2) {
              if (answers[0] === "" && answers[1] === "") {
                answers[0] = "nil";
                answers[1] = "nil";
              }
            }

            this.graderResponses.forEach((r, i) => {
              const br = document.createElement("br");
              const span = document.createElement("span");
              span.classList.add("grader-response");
              span.classList.add("pl-1");
              if (
                r.responses.data &&
                r.responses.data.hasOwnProperty(questionKey)
              ) {
                const label = this.getLabelFromSchema(
                  this.gradingFormSchema,
                  questionKey,
                  r.responses.data[questionKey]
                );
                const displayLabel = label ? label : "nil";
                this.getGraderName(r["user_id"])
                  .then((userName) => {
                    span.append(`${userName}: ${displayLabel}`);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                if (valToCompare === "rand1234")
                  valToCompare = r.responses.data[questionKey];
                else if (r.responses.data[questionKey] !== valToCompare)
                  match = false;
              } else {
                span.append(`Grader ${i + 1}: Nill`);
                match = false;
              }

              graderAnswersDiv.append(span);
              graderAnswersDiv.append(br);
            });

            if (!match) {
              graderAnswersDiv.classList.add(
                "mt-1",
                "border",
                "rounded",
                "border-red-400"
              );
            } else {
              graderAnswersDiv.classList.add(
                "mt-1",
                "border",
                "rounded",
                "border-green-400"
              );
            }

            c.element.append(graderAnswersDiv);
          }
        }
      });
    }
  }

  getGraderName(userId) {
    return fetch(this.graderNameUrl, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: "POST",
      body: JSON.stringify({
        user: {
          user_id: userId,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => data.full_name);
        } else {
          throw new Error("Failed to fetch user data");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getLabelFromSchema(schema, questionKey, questionValue) {
    for (const component of schema.components) {
      if (component.components) {
        // If the component has nested questions, iterate through them
        for (const question of component.components) {
          const targetKey = question["key"];
          const checkboxes = [];
          if (
            question.values &&
            question["type"] === "radio" &&
            targetKey === questionKey
          ) {
            for (const item of question.values) {
              if (item["value"] == questionValue) {
                return item["label"];
              }
            }
          } else if (
            question.values &&
            question["type"] === "selectboxes" &&
            targetKey === questionKey
          ) {
            for (var key in questionValue) {
              if (questionValue[key]) {
                // Use the current key to access the corresponding element in question.values
                checkboxes.push(question.values[key - 1]["label"]);
              }
            }
            return checkboxes;
          }
        }
      }
    }
    return questionValue;
  }

  appendAdjudicationImageWithQuestion(component, imageUrls, imagesArray) {
    if (
      component.element &&
      component.element.querySelector(".adjudication-screenshots") &&
      component.element.querySelector(".adjudication-images")
    ) {
      return; // If already appended, exit the function
    }
    const addedImages = new Set();
    // Add a span for grading images if gradingImage is true
    const gradingSpan = document.createElement("p");
    gradingSpan.classList.add("adjudication-screenshots");
    gradingSpan.textContent = "Adjudication Screenshots";

    // Create a div to hold the images
    const imageDiv = document.createElement("div");
    imageDiv.classList.add(
      "mt-1",
      "border",
      "rounded",
      "border-green-300",
      "flex",
      "gap-2",
      "adjudication-images"
    );

    // Iterate through the image URLs and create image elements
    for (const imageUrl of imageUrls) {
      for (let i = 0; i < imagesArray.length; i++) {
        let imageUrlParts = imagesArray[i].split("/");
        let imageName = imageUrlParts[imageUrlParts.length - 1];
        // Decode the URL containing %20
        let decodedImageUrl = decodeURIComponent(imageName);

        if (imageUrl == decodedImageUrl && !addedImages.has(decodedImageUrl)) {
          const fileExtension = imageName.split(".").pop().toLowerCase();
          if (fileExtension === "pdf") {
            // Handle PDF files
            const pdfIconElement = document.createElement("img");
            pdfIconElement.setAttribute("src", this.pdfIcon); // Replace with actual PDF icon path
            pdfIconElement.setAttribute("alt", "PDF Attachment");
            pdfIconElement.setAttribute("width", "100");
            pdfIconElement.setAttribute("height", "100");
            pdfIconElement.style.cursor = "pointer";

            const pdfElement = document.createElement("a");
            pdfElement.setAttribute("href", imagesArray[i]);
            pdfElement.setAttribute("target", "_blank"); // Open in a new tab
            pdfElement.title = "PDF Attachment";
            pdfElement.style.cursor = "pointer";

            // Append the icon to the anchor element
            pdfElement.appendChild(pdfIconElement);

            if (component.element) {
              imageDiv.appendChild(pdfElement);
              addedImages.add(decodedImageUrl);
            }
          } else {
            // Handle image files
            const imageElement = document.createElement("img");
            imageElement.setAttribute("src", imagesArray[i]);
            imageElement.setAttribute("alt", "Attachment Image");
            imageElement.setAttribute("width", "100");
            imageElement.setAttribute("height", "100");
            imageElement.style.cursor = "pointer";
            imageElement.addEventListener("click", function () {
              const lightboxImage = document.getElementById("lightbox-image");
              lightboxImage.src = imagesArray[i];
              const lightboxContainer =
                document.getElementById("lightbox-container");
              lightboxContainer.style.display = "block";
            });

            const lightboxContainer =
              document.getElementById("lightbox-container");
            lightboxContainer.addEventListener("click", function () {
              lightboxContainer.style.display = "none";
            });

            if (component.element) {
              imageDiv.appendChild(imageElement);
              addedImages.add(decodedImageUrl);
            }
          }
        }
      }
    }

    // Check if both imageDiv and gradingSpan are not empty before appending
    if (
      imageDiv.innerHTML.trim() !== "" &&
      gradingSpan.innerHTML.trim() !== ""
    ) {
      // Check if component.element exists before appending
      if (component.element) {
        // Append the image div to the question component's element
        component.element.appendChild(imageDiv);
        component.element.appendChild(gradingSpan);
      }
    }
  }

  appendGradingImageWithQuestion(
    component,
    questionId,
    imageUrls,
    imagesArray
  ) {
    // Check if imageDiv and gradingSpan are already appended
    if (
      component.element &&
      component.element.querySelector(".grading-screenshots") &&
      component.element.querySelector(".grading-images")
    ) {
      return; // If already appended, exit the function
    }
    // Extract image URLs and usernames
    const extractedData = imagesArray.map((item) => {
      if (item.includes("<===>")) {
        let dataBeforeSeparator = item.split("<===>")[0].trim();
        return dataBeforeSeparator;
      }
    });

    const userNames = imagesArray.map((item) => {
      if (item.includes("<===>")) {
        let dataAfterSeparator = item.split("<===>")[1].trim();
        return dataAfterSeparator;
      }
    });

    // Add a span for grading images if gradingImage is true
    const gradingSpan = document.createElement("p");
    gradingSpan.classList.add("grading-screenshots");
    gradingSpan.textContent = "Grading Screenshots";

    // Create a div to hold the images
    const addedImages = new Set();
    const imageDiv = document.createElement("div");
    imageDiv.classList.add(
      "mt-1",
      "border",
      "rounded",
      "border-green-300",
      "flex",
      "gap-2",
      "grading-images"
    );

    // Iterate through the image URLs and create image elements
    for (const key in imageUrls) {
      if (key === questionId) {
        for (let j = 0; j < imageUrls[key].length; j++) {
          for (let i = 0; i < extractedData.length; i++) {
            if (extractedData[i]) {
              let imageUrlParts = extractedData[i].split("/");
              let encodedImageName = imageUrlParts[imageUrlParts.length - 1];
              let imageName = decodeURIComponent(encodedImageName);
              if (
                imageUrls[key][j] === imageName &&
                !addedImages.has(imageName)
              ) {
                const fileExtension = encodedImageName
                  .split(".")
                  .pop()
                  .toLowerCase();
                if (fileExtension === "pdf") {
                  const pdfIconElement = document.createElement("img");
                  pdfIconElement.setAttribute("src", this.pdfIcon); // Replace with actual PDF icon path
                  pdfIconElement.setAttribute("alt", "sdPDF Attachment");
                  pdfIconElement.setAttribute("width", "100");
                  pdfIconElement.setAttribute("height", "100");
                  pdfIconElement.style.cursor = "pointer";

                  const pdfElement = document.createElement("a");
                  pdfElement.setAttribute("href", extractedData[i]);
                  pdfElement.setAttribute("target", "_blank"); // Open in a new tab
                  pdfElement.title = `Uploaded by: ${userNames[i]}`;
                  pdfElement.style.cursor = "pointer";
                  // Append the icon to the anchor element
                  pdfElement.appendChild(pdfIconElement);
                  // Check if component.element exists before appending
                  if (component.element) {
                    imageDiv.appendChild(pdfElement);
                    addedImages.add(imageName);
                  }
                } else {
                  const imageElement = document.createElement("img");
                  imageElement.setAttribute("src", extractedData[i]);
                  imageElement.setAttribute("alt", "Attachment Image");
                  imageElement.setAttribute("width", "100");
                  imageElement.setAttribute("height", "100");
                  imageElement.style.cursor = "pointer";
                  imageElement.setAttribute(
                    "title",
                    `Uploaded by: ${userNames[i]}`
                  );
                  imageElement.addEventListener("click", function () {
                    const lightboxImage =
                      document.getElementById("lightbox-image");
                    const lightboxUsername =
                      document.getElementById("lightbox-username");
                    lightboxImage.src = extractedData[i];
                    lightboxUsername.textContent =
                      "Uploaded by : " + userNames[i];
                    const lightboxContainer =
                      document.getElementById("lightbox-container");
                    lightboxContainer.style.display = "block";
                  });

                  const lightboxContainer =
                    document.getElementById("lightbox-container");
                  lightboxContainer.addEventListener("click", function () {
                    lightboxContainer.style.display = "none";
                    const lightboxUsername =
                      document.getElementById("lightbox-username");
                    lightboxUsername.textContent = "";
                  });

                  // Check if component.element exists before appending
                  if (component.element) {
                    // Append the image to the div
                    imageDiv.appendChild(imageElement);
                    addedImages.add(imageName);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Check if both imageDiv and gradingSpan are not empty before appending
    if (
      imageDiv.innerHTML.trim() !== "" &&
      gradingSpan.innerHTML.trim() !== ""
    ) {
      // Check if component.element exists before appending
      if (component.element) {
        // Append the image div and grading span to the question component's element
        component.element.appendChild(imageDiv);
        component.element.appendChild(gradingSpan);
      }
    }
  }

  checkAdjudicationLogic(questionKey, originalForm) {
    // Iterate through the components in the originalForm
    for (const component of originalForm) {
      // Check if the current component has the specified questionKey
      for (const question of component["components"]) {
        if (question.key === questionKey) {
          // Check the adjudication for the component
          if (
            question.adjudication &&
            question.adjudication["status"] === "Required" &&
            question.adjudication["base_on"] === "Any Change"
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }

  appendSdvWithQuestionTitles(component, check_sdv, sdv_comments) {
    if (check_sdv === "true") {
      // Create a span element for the SVG icon with the tooltip
      const svgDive = document.createElement("span");
      svgDive.classList.add("has-tooltip");
      svgDive.innerHTML = `
          <?xml version="1.0" encoding="iso-8859-1"?>
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg fill="#007BFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="16px" height="16px" viewBox="0 0 509.604 509.604"
                xml:space="preserve" style ="margin-top:3px">
                <g>
                    <g>
                        <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                            c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                            c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                            c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                            c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                            c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                            c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                            c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                            c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                            C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                            c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                            l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                            c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                            l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
                    </g>
                </g>
            </svg>     
            <span class="tooltip bottom-2 w-min">${sdv_comments}</span>
          `;
      // Get the label element within component.element
      const labelElement = component.element.querySelector("label");
      labelElement.classList.add("flex", "gap-2");
      // Concatenate component.label and svgDive and set it as the innerHTML of labelElement
      labelElement.innerHTML = component.label + svgDive.outerHTML;
      // Optionally, you can also console.log the updated label
      console.log(labelElement.innerHTML);
    }
  }

  loadExistingAnnotationsForImage(url) {
    let annotation = {};
    if (this.annotationUsersElem.value === "adjudicator") {
      annotation = this.annotation[url];
    } else {
      annotation = this.graderAnnotations[this.annotationUsersElem.value][url];
    }
    // this check avoids reloads between next and previous loads
    if (annotation && !this.annotationIsLoadedOnce[url]) {
      cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(
        url,
        annotation
      );
      this.annotationIsLoadedOnce[url] = true;
      this.refreshTools();
    }
  }

  refreshTools() {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
    ];
    tools.forEach((tool) => {
      cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
    });
  }

  readImages() {
    const images = document.querySelectorAll(".image");
    images.forEach((i) => {
      this.images.push({
        id: i.getAttribute("data-id"),
        url: i.getAttribute("data-url"),
        elem: i,
      });
    });
  }

  loadImage(url, id) {
    url = document.location.origin + "/" + url;
    cornerstone.loadAndCacheImage(url).then((image) => {
      this.loadExistingAnnotationsForImage(url);
      cornerstone.displayImage(this.viewerElement, image);
      cornerstone.enable(this.viewerElement);
    });
  }

  changeImage(e, elem) {
    let target;
    if (e) target = e.target;
    else target = elem;
    // load new image
    this.loadImage(target.dataset.url, target.dataset.id);
    // if the call came from click adjust the currentImage index
    if (e) {
      this.currentImage = target.dataset.idx;
      console.log(`current index seleted ${this.currentImage}`);
    }
    // remove all active borders
    const allImageBoxes = document.querySelectorAll(".image-box");
    allImageBoxes.forEach((i) => i.classList.remove("border-primary-500"));
    // active border around current image
    target.parentNode.classList.add("border-primary-500");
  }

  nextImage(e) {
    const len = this.images.length;
    this.currentImage = Math.abs((this.currentImage + 1) % len);
    this.changeImage(null, this.images[this.currentImage].elem);
    e.preventDefault();
  }

  previousImage(e) {
    const len = this.images.length;
    this.currentImage = Math.abs(this.currentImage + len - 1) % len;
    this.changeImage(null, this.images[this.currentImage].elem);
    e.preventDefault();
  }

  changGradingUser(e) {
    const currentImageUrl = this.images[this.currentImage].url;
    this.annotationIsLoadedOnce = {}; // [document.location.origin + currentImageUrl] = null;
    if (this.annotationUsersElem.value === "adjudicator") {
      // TODO: Enable all tools and hide the toolbar
      this.loadImage(currentImageUrl);
    } else {
      // TODO: Disable all tools and hide the toolbar
      this.loadImage(currentImageUrl);
    }
  }

  labelTool(e) {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
    ];
    tools.forEach((tool) => {
      cornerstoneTools.setToolEnabled(tool, { mouseButtonMask: LEFT_CLICK });
    });
    this.viewerElement.addEventListener("cornerstonetoolsmousedown", (e) => {
      const coord = e.detail.currentPoints.canvas;
      const mouseBtn = e.detail.buttons;
      // if its a right click
      if (mouseBtn === 2) {
        tools.forEach((tool) => {
          const state = cornerstoneTools.getToolState(this.viewerElement, tool);
          if (state) {
            state.data.forEach((d) => {
              const isNear = cornerstoneTools
                .getToolForElement(this.viewerElement, tool)
                .__proto__.pointNearTool(this.viewerElement, d, coord);
              if (isNear)
                console.log(`${tool} with ${d.uuid} near : ${isNear}`);
              cornerstoneTools.setToolActive(tool, {
                mouseButtonMask: LEFT_CLICK,
              });
              // when near is detected see if we can have the annotation highlited green
            });
          }
        });
      }
    });
    e.preventDefault();
  }

  levelsTool(e) {
    cornerstoneTools.setToolActive("Wwwc", { mouseButtonMask: LEFT_CLICK });
    e.preventDefault();
  }

  panTool(e) {
    cornerstoneTools.setToolActive("Pan", { mouseButtonMask: LEFT_CLICK });
    e.preventDefault();
  }

  lengthTool(e) {
    cornerstoneTools.setToolActive("Length", { mouseButtonMask: LEFT_CLICK });
    e.preventDefault();
  }

  arrowAnnotationTool(e) {
    cornerstoneTools.setToolActive("ArrowAnnotate", {
      mouseButtonMask: LEFT_CLICK,
    });
    e.preventDefault();
  }

  angleTool(e) {
    cornerstoneTools.setToolActive("Angle", { mouseButtonMask: LEFT_CLICK });
    e.preventDefault();
  }

  rectangleTool(e) {
    cornerstoneTools.setToolActive("RectangleRoi", {
      mouseButtonMask: LEFT_CLICK,
    });
    e.preventDefault();
  }

  freehandTool(e) {
    cornerstoneTools.setToolActive("FreehandRoi", {
      mouseButtonMask: LEFT_CLICK,
    });
    e.preventDefault();
  }

  ellipseTool(e) {
    cornerstoneTools.setToolActive("EllipticalRoi", {
      mouseButtonMask: LEFT_CLICK,
    });
    e.preventDefault();
  }

  eraserTool(e) {
    cornerstoneTools.setToolActive("Eraser", { mouseButtonMask: LEFT_CLICK });
    e.preventDefault();
  }
  /**
   * TODO: combing submitGrading and saveGrading functions. They no longer need to be split up
   */
  submitGrading(e) {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
    ];
    // const annotations = {};
    // https://groups.google.com/g/cornerstone-platform/c/QWHTGgkLufo
    // https:// github.com/cornerstonejs/cornerstoneTools/blob/3925399f72e69f69a4b108be10bfce115eda3247/src/stateManagement/imageIdSpecificStateManager.js
    const annotations =
      cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
    // tools.forEach(tool => {
    //     const toolState = cornerstoneTools.getToolState(this.viewerTarget, tool);
    //     annotations[tool] = toolState;
    // });
    this.saveGrading(annotations);
    e.preventDefault();
  }

  saveGrading(annotations) {
    fetch(this.gradingSaveUrl, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: "PATCH",
      body: JSON.stringify({
        other_adjudication: {
          annotations: annotations,
        },
      }),
    }).then((response) => {
      console.log(response);
    });
  }

  get url() {
    return this.data.get("url");
  }

  // formio functions
  formSaveDraft(e) {
    this.handleFormCommitAndSubmit(e, "initiated", true, true);
  }

  formCommit(e) {
    if (this.gradingFormAgreementElem.checked) {
      this.handleFormCommitAndSubmit(e, "completed", true);
    } else {
      alert("You must agree to terms");
    }
    e.preventDefault();
  }

  formUpdate(e) {
    this.handleFormCommitAndSubmit(e, "editing");
  }

  handleFormCommitAndSubmit(
    e,
    status,
    shouldSubmit = false,
    draftStatus = false
  ) {
    e.preventDefault();

    // Validate the form before submission
    this.gradingForm
      .submit()
      .then((submission) => {
        // Assuming you have this.gradingImages defined in your controller
        this.formSubmissionModule.collectImagesFromSelector(
          ".attached_images",
          this.adjImages
        );
        this.defaultSaveAnnotation();
        // Handle the form submission after commit is successful
        return this.formSubmissionModule.submitForm(
          submission,
          "PATCH",
          null,
          null,
          draftStatus,
          "other_adjudication",
          status
        );
      })
      .catch((error) => {
        console.error(error);
        // You can also handle any additional error logic here if needed
      });
  }

  defaultSaveAnnotation() {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
    ];
    // const annotations = {};
    const annotations =
      cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
    this.saveGrading({ ...annotations, ...this.annotation });
  }

  regrade(e) {
    this.regradingFormElem.classList.toggle("hidden");
    e.preventDefault();
  }

  confirmRegrade(e) {
    const markedForRegrade = this.regradersElems
      .filter((c) => c.checked)
      .map((c) => c.getAttribute("data-user"));
    if (markedForRegrade.length) {
      this.saveRegradeRequest(markedForRegrade);
    }
    e.preventDefault();
  }

  // TODO: Refactor and extract all the ajax calls (3 in this controller) to a common utility and DRY it up
  saveRegradeRequest(userIDs) {
    if (userIDs && userIDs.length) {
      console.log(`requested regrading on users ${userIDs.join(",")}`);
      fetch(this.regradingUrl, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": document.head
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        method: "PATCH",
        body: JSON.stringify({
          stack: {
            user_ids: userIDs,
          },
        }),
      }).then((response) => {
        console.log(response);
      });
    } else {
      console.log("No users were selected for regrading");
    }
  }

  toggleImage() {
    if (this.cornerstoneViewer.dataset.expanded === "1") {
      this.collapse_cornerstone();
    } else {
      this.expand_cornerstone();
    }
  }

  collapse_cornerstone() {
    const grading_tools =
      this.cornerstoneViewer.querySelector(".grading_tools");
    this.cornerstoneViewer.classList.add("col-span-1");
    this.cornerstoneViewer.classList.remove("col-span-6");
    grading_tools.classList.add("hidden");
    this.viewerTarget.classList.add("hidden");
    this.cornerstoneViewer.dataset.expanded = "0";
    this.cornerstoneIconTarget.classList.add("p-2");
    this.cornerstoneIconTarget.classList.add("rounded-sm");
    this.cornerstoneIconTarget.classList.add("text-white");
    this.cornerstoneIconTarget.innerHTML = `VIEWER`;
    this.largeForm();
  }

  largeForm() {
    this.formSection.classList.add("col-span-10");
    this.formSection.classList.remove("col-span-5");
  }

  expand_cornerstone() {
    const grading_tools =
      this.cornerstoneViewer.querySelector(".grading_tools");
    this.cornerstoneViewer.classList.add("col-span-6");
    this.cornerstoneViewer.classList.remove("col-span-1");
    grading_tools.classList.remove("hidden");
    this.viewerTarget.classList.remove("hidden");
    this.cornerstoneViewer.dataset.expanded = "1";
    this.cornerstoneIconTarget.classList.remove("p-2");
    this.cornerstoneIconTarget.classList.remove("rounded-sm");
    this.cornerstoneIconTarget.classList.remove("text-white");
    this.cornerstoneIconTarget.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
    </svg>`;
    this.reversLargeForm();
  }

  reversLargeForm() {
    this.formSection.classList.remove("col-span-10");
    this.formSection.classList.add("col-span-5");
  }

  toggleSubmitArea() {
    var staticPanel = document.getElementById("expend_colapse");
    // console.log(staticPanel.classList.re);
    if (this.questionAreaTarget.classList.contains("hidden")) {
      this.questionAreaTarget.classList.remove("hidden");
      staticPanel.classList.add("fa-minus-square-o");
      staticPanel.classList.remove("fa-plus-square-o");
    } else {
      this.questionAreaTarget.classList.add("hidden");
      staticPanel.classList.add("fa-plus-square-o");
      staticPanel.classList.remove("fa-minus-square-o");
    }
  }

  fetchPreviousVisitAnswers() {
    event.preventDefault();
    const method = "POST";
    fetch(this.gradingPreviousUrl, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: method,
      body: JSON.stringify({
        stack_id: this.gradingFormStackId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "Found") {
          if (!data || typeof data !== "object") {
            throw new Error("Invalid JSON data received");
          }
          const previousGradingFormData = data.responses.concat(
            data.history_reponse
          );
          const userIDs = data.user_names;
          const previousVisitLabel = data.previous_visit_label;
          const previousVisitDate = data.previous_date;
          const formStatuses = data.status;
          const visitDates = data.history_visit_dates;
          const visitIds = data.history_visits_ids;
          const previousStatusArray = Array.isArray(data.previous_status)
            ? data.previous_status
            : [];
          if (data.responses.length !== userIDs.length) {
            console.error(
              "Length mismatch between previousGradingFormData and userIDs"
            );
          } else {
            if (previousGradingFormData.length > 0) {
              const firstPreviousStatus =
                previousStatusArray.length > 0 ? previousStatusArray[0] : null;
              this.appendPreviousVisitAnsToGradingForm(
                previousGradingFormData[0],
                firstPreviousStatus,
                previousVisitLabel,
                previousVisitDate
              );
            }

            for (let i = 1; i < previousGradingFormData.length; i++) {
              const visitDate =
                visitDates.length > i - 1 ? visitDates[i - 1] : null;
              const visitId = visitIds.length > i - 1 ? visitIds[i - 1] : null;
              const formStatus =
                formStatuses.length > i - 1 ? formStatuses[i - 1] : null;
              this.appendPreviousVisitAnsToGradingForm(
                previousGradingFormData[i],
                null,
                previousVisitLabel,
                previousVisitDate,
                formStatus,
                visitDate,
                visitId,
                true
              );
            }
          }

          document
            .getElementById("viewPreviousAnsButton")
            .classList.add("hidden");
          document
            .getElementById("hidePreviousAnsButton")
            .classList.remove("hidden");
        } else if (data.message == "No previous visit Found") {
          alert("No previous visit Available");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  hidePreviousAns(event) {
    event.preventDefault();

    // Remove the appended data by removing the .adj-answers-div elements
    const graderAnswersDivs = document.querySelectorAll(".adj-answers-div");
    graderAnswersDivs.forEach((div) => {
      div.remove();
    });

    // Show the "View Previous Ans" button
    const viewPreviousAnsButton = document.getElementById(
      "viewPreviousAnsButton"
    );
    viewPreviousAnsButton.classList.remove("hidden");

    // Hide the "Hide Previous Ans" button
    const hidePreviousAnsButton = document.getElementById(
      "hidePreviousAnsButton"
    );
    hidePreviousAnsButton.classList.add("hidden");
  }

  appendPreviousVisitAnsToGradingForm(
    previousGradingFormData,
    previousStatus,
    previousVisitLabel,
    previousVisitDate,
    formStatus,
    visitDate,
    visitId,
    isSubsequentCall = false
  ) {
    FormioUtils.eachComponent(this.gradingForm.components, (c) => {
      if (
        c &&
        c.component &&
        c.element &&
        !c.element.classList.contains("formio-hidden")
      ) {
        let graderAnswersDiv = c.element.querySelector(".adj-answers-div");
        if (!graderAnswersDiv) {
          graderAnswersDiv = document.createElement("div");
          graderAnswersDiv.classList.add("adj-answers-div");
          c.element.appendChild(graderAnswersDiv);
        }

        const questionKey = c.component.key;

        if (previousGradingFormData.data.hasOwnProperty(questionKey)) {
          const label =
            this.getLabelFromSchema(
              this.gradingFormSchema,
              questionKey,
              previousGradingFormData.data[questionKey]
            ) || "nil";
          const timestamp = new Date(previousVisitDate);
          const formattedDate = `${timestamp.getFullYear()}-${String(
            timestamp.getMonth() + 1
          ).padStart(2, "0")}-${String(timestamp.getDate()).padStart(2, "0")}`;
          let currentFormStatus = formStatus;
          if (currentFormStatus === "completed") {
            currentFormStatus = "[a]";
          } else if (currentFormStatus === "editing") {
            currentFormStatus = "[e]";
          } else {
            currentFormStatus = "[p]";
          }
          let previousFormStatus = previousStatus;
          if (previousFormStatus === "completed") {
            previousFormStatus = "[a]";
          } else if (previousFormStatus === "editing") {
            previousFormStatus = "[e]";
          } else {
            previousFormStatus = "[p]";
          }
          const answerTextSpan = document.createElement("span");
          answerTextSpan.classList.add("has-tooltip");
          answerTextSpan.innerHTML = !isSubsequentCall
            ? `
                        ${previousVisitLabel}: ${label} (${formattedDate}) ${previousFormStatus}
                    `
            : "";

          if (!isSubsequentCall) {
            // Append the answerTextSpan to graderAnswersDiv
            graderAnswersDiv.appendChild(answerTextSpan);

            // Add the 'has-tooltip' class to the answerTextSpan
            answerTextSpan.classList.add("has-tooltip");

            // Create the tooltip span
            const tooltipSpan = document.createElement("span");
            tooltipSpan.classList.add("tooltip");

            // Append the tooltip span to the answerTextSpan
            answerTextSpan.appendChild(tooltipSpan);
          } else {
            // Find the first element with the class 'has-tooltip'
            const firstAnswerSpan =
              graderAnswersDiv.querySelector(".has-tooltip");

            if (firstAnswerSpan) {
              const tooltipSpan = firstAnswerSpan.querySelector(".tooltip");

              if (tooltipSpan) {
                // Safely append new content to the tooltip
                tooltipSpan.innerHTML += `${visitId}: ${label} (${visitDate}) ${currentFormStatus}<br>`;
              } else {
                console.error("Tooltip span not found inside has-tooltip span");
              }
            } else {
              // Optional: Create a new span if not found
              const newAnswerSpan = document.createElement("span");
              newAnswerSpan.classList.add("has-tooltip");
              graderAnswersDiv.appendChild(newAnswerSpan);

              const newTooltipSpan = document.createElement("span");
              newTooltipSpan.classList.add("tooltip");
              newAnswerSpan.appendChild(newTooltipSpan);
              // Now update the new tooltip span with the content
              newTooltipSpan.innerHTML += `${visitId}: ${label} (${visitDate}) ${currentFormStatus}<br>`;
            }
          }
        }
      }
    });
  }

  getLabelFromSchema(schema, questionKey, questionValue) {
    for (const component of schema.components) {
      if (component.components) {
        // If the component has nested questions, iterate through them
        for (const question of component.components) {
          const targetKey = question["key"];
          const checkboxes = [];
          if (
            question.values &&
            question["type"] === "radio" &&
            targetKey === questionKey
          ) {
            for (const item of question.values) {
              if (item["value"] == questionValue) {
                return item["label"];
              }
            }
          } else if (
            question.values &&
            question["type"] === "selectboxes" &&
            targetKey === questionKey
          ) {
            for (var key in questionValue) {
              if (questionValue[key]) {
                // Use the current key to access the corresponding element in question.values
                checkboxes.push(question.values[key - 1]["label"]);
              }
            }
            return checkboxes;
          }
        }
      }
    }
    return questionValue;
  }
}
