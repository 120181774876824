import { Controller } from "stimulus";
import Swal from "sweetalert2";
export default class extends Controller {
  static targets = ["menulist", "content", "stackVisitDateInput"];

  connect() {
    this.modal = document.getElementById("popup-modal");
  }

  updateStackDate() {
    const stackId =
      this.stackVisitDateInputTarget.getAttribute("data-stack-id");
    const studyId = this.stackVisitDateInputTarget.getAttribute(
      "data-stack-study-id"
    );
    const subjectId = this.stackVisitDateInputTarget.getAttribute(
      "data-stack-subject-id"
    );
    const visitDate = this.stackVisitDateInputTarget.value;
    const checkUrl =
      document.location.origin +
      `/studies/${studyId}/subjects/${subjectId}/update_date`;
    console.log(stackId);
    console.log(visitDate);
    fetch(checkUrl, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: "PATCH",
      body: JSON.stringify({
        studyId: studyId,
        subjectId: subjectId,
        stackId: stackId,

        visitDate: visitDate,
      }),
    }).then((response) => {
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Successfully Done",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "An error occurreds",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  toggleForm() {
    const currentStatus = this.element.dataset.visitStatus;
    const currentDmStatus = this.element.dataset.dmStatus;
    const newStatus = currentStatus === "open" ? "locked" : "open";
    const userRole = this.element.dataset.userRole;
    let confirmationMessage = "";
    let warningMessage = "";

    if (userRole === "data_manager" && newStatus === "locked") {
      confirmationMessage = `Lock`;
    } else if (userRole === "data_manager" && currentDmStatus === "false") {
      confirmationMessage = `Lock`;
    } else if (userRole === "data_manager" && currentDmStatus === "true") {
      confirmationMessage = `Unlock`;
    } else if (userRole === "project_admin" && newStatus === "locked") {
      confirmationMessage = `Lock`;
    } else {
      confirmationMessage = `Unlock`;
    }

    if (userRole === "data_manager" && newStatus === "locked") {
      warningMessage = `Are you sure you want to lock this visit completely?`;
    } else if (userRole === "data_manager" && currentDmStatus === "false") {
      warningMessage = `Are you sure you want to lock this visit completely?`;
    } else if (userRole === "data_manager" && currentDmStatus === "true") {
      warningMessage = `Are you sure you want to unlock this visit?`;
    } else if (userRole === "project_admin" && newStatus === "locked") {
      warningMessage = `Are you sure you want to lock the grading form?`;
    } else if (userRole === "project_admin" && currentDmStatus === "true") {
      warningMessage = `This visit is lock completely only Data manager can open it!`;
    } else {
      warningMessage = `Are you sure you want to ${confirmationMessage} the grading form?`;
    }
    let swalOptions = {
      title: warningMessage,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    };

    if (userRole === "project_admin" && currentDmStatus === "true") {
      swalOptions.showConfirmButton = false; // Hide the confirm button
    } else {
      swalOptions.confirmButtonText = `Yes, ${confirmationMessage} it!`; // Add the confirm button if the condition is not met
    }
    // Customize based on role and action

    if (
      (userRole === "project_admin" &&
        newStatus === "open" &&
        currentDmStatus === "false") ||
      (userRole === "data_manager" &&
        newStatus === "open" &&
        currentDmStatus === "true")
    ) {
      swalOptions.html = `
            <textarea id="action-reason" class="swal2-textarea" placeholder="Reason for ${confirmationMessage.toLowerCase()}ing..." aria-label="Reason for ${confirmationMessage.toLowerCase()}ing..." cols="30"></textarea>
        `;
      swalOptions.preConfirm = () => {
        const reason = document.getElementById("action-reason").value;
        if (!reason) {
          Swal.showValidationMessage(
            `Please enter a reason to ${confirmationMessage.toLowerCase()}...`
          );
          return false;
        }
        return { reason };
      };
    }

    if (
      (userRole === "data_manager" && newStatus === "locked") ||
      (userRole === "data_manager" && currentDmStatus === "false")
    ) {
      const existingHtml = swalOptions.html || "";
      swalOptions.html = `
            ${existingHtml}
            <div style="margin-top: 10px;">
                <label>
                    <input type="checkbox" id="action-acknowledge" />
                    Data transfer: Lock the visit completely!
                </label>
            </div>
        `;
      swalOptions.preConfirm = () => {
        const checkbox = document.getElementById("action-acknowledge").checked;

        if (!checkbox) {
          Swal.showValidationMessage(
            `Please acknowledge the consequences to ${confirmationMessage.toLowerCase()}...`
          );
          return false;
        }
        return { checkbox };
      };
    }

    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        const reason =
          (userRole === "project_admin" || userRole === "data_manager") &&
          newStatus === "open"
            ? result.value.reason
            : null;

        const checkbox =
          userRole === "data_manager" && currentDmStatus === "false"
            ? result.value.checkbox
            : null;

        this.updateVisitStatus(newStatus, reason, checkbox, userRole);
      }
    });
  }

  AddtionaltoggleForm() {
    const currentStatus = this.element.dataset.visitStatus;
    const currentDmStatus = this.element.dataset.dmStatus;
    const newStatus = currentStatus === "open" ? "locked" : "open";
    const userRole = this.element.dataset.userRole;
    let confirmationMessage = "";
    let warningMessage = "";
    if (userRole === "data_manager" && newStatus === "locked") {
      confirmationMessage = `Lock`;
    } else if (userRole === "data_manager" && currentDmStatus === "false") {
      confirmationMessage = `Lock`;
    } else if (userRole === "data_manager" && currentDmStatus === "true") {
      confirmationMessage = `Unlock`;
    } else if (userRole === "project_admin" && newStatus === "locked") {
      confirmationMessage = `Lock`;
    } else {
      confirmationMessage = `Unlock`;
    }

    if (userRole === "data_manager" && newStatus === "locked") {
      warningMessage = `Are you sure you want to lock this visit completely?`;
    } else if (userRole === "data_manager" && currentDmStatus === "false") {
      warningMessage = `Are you sure you want to lock this visit completely?`;
    } else if (userRole === "data_manager" && currentDmStatus === "true") {
      warningMessage = `Are you sure you want to unlock this visit?`;
    } else if (userRole === "project_admin" && newStatus === "locked") {
      warningMessage = `Are you sure you want to lock the grading form?`;
    } else if (userRole === "project_admin" && currentDmStatus === "true") {
      warningMessage = `This visit is lock completely only Data manager can open it!`;
    } else {
      warningMessage = `Are you sure you want to ${confirmationMessage} the grading form?`;
    }
    let swalOptions = {
      title: warningMessage,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    };

    if (userRole === "project_admin" && currentDmStatus === "true") {
      swalOptions.showConfirmButton = false; // Hide the confirm button
    } else {
      swalOptions.confirmButtonText = `Yes, ${confirmationMessage} it!`; // Add the confirm button if the condition is not met
    }
    // Customize based on role and action

    if (
      (userRole === "project_admin" &&
        newStatus === "open" &&
        currentDmStatus === "false") ||
      (userRole === "data_manager" &&
        newStatus === "open" &&
        currentDmStatus === "true")
    ) {
      swalOptions.html = `
            <textarea id="action-reason" class="swal2-textarea" placeholder="Reason for ${confirmationMessage.toLowerCase()}ing..." aria-label="Reason for ${confirmationMessage.toLowerCase()}ing..." cols="30"></textarea>
        `;
      swalOptions.preConfirm = () => {
        const reason = document.getElementById("action-reason").value;
        if (!reason) {
          Swal.showValidationMessage(
            `Please enter a reason to ${confirmationMessage.toLowerCase()}...`
          );
          return false;
        }
        return { reason };
      };
    }

    if (
      (userRole === "data_manager" && newStatus === "locked") ||
      (userRole === "data_manager" && currentDmStatus === "false")
    ) {
      const existingHtml = swalOptions.html || "";
      swalOptions.html = `
            ${existingHtml}
            <div style="margin-top: 10px;">
                <label>
                    <input type="checkbox" id="action-acknowledge" />
                    Data transfer: Lock the visit completely!
                </label>
            </div>
        `;
      swalOptions.preConfirm = () => {
        const checkbox = document.getElementById("action-acknowledge").checked;

        if (!checkbox) {
          Swal.showValidationMessage(
            `Please acknowledge the consequences to ${confirmationMessage.toLowerCase()}...`
          );
          return false;
        }
        return { checkbox };
      };
    }

    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        const reason =
          (userRole === "project_admin" || userRole === "data_manager") &&
          newStatus === "open"
            ? result.value.reason
            : null;

        const checkbox =
          userRole === "data_manager" && currentDmStatus === "false"
            ? result.value.checkbox
            : null;

        this.updateAdditonalVisitStatus(newStatus, reason, checkbox, userRole);
      }
    });
  }

  toggle(event) {
    const clickedElement = event.currentTarget;
    const stackId = event.currentTarget.getAttribute(
      "data-sdv-verified-modal-id"
    );
    const contentRow = document.querySelectorAll(`.additional-info${stackId}`);
    if (contentRow) {
      contentRow.forEach((element) => {
        element.classList.toggle("hidden");
        const tr = clickedElement.closest("tr");
        if (tr) {
          const tdButtonIcon = tr.querySelector(".buttonIcon");
          const icon = tdButtonIcon.querySelector("svg");
          if (element.classList.contains("hidden")) {
            icon.innerHTML =
              '<svg fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.05 10.157l2.122-2.121L6.05 5.914 7.464 4.5 11 8.036 7.464 11.57 6.05 10.157zM8 0c4.418 0 8 3.59 8 8 0 4.418-3.59 8-8 8-4.418 0-8-3.59-8-8 0-4.418 3.59-8 8-8zm0 2C4.693 2 2 4.686 2 8c0 3.307 2.686 6 6 6 3.307 0 6-2.686 6-6 0-3.307-2.686-6-6-6z" fill-rule="evenodd"></path> </g></svg>';
          } else {
            icon.innerHTML =
              '<svg fill="#008000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.843 6.05l2.121 2.122 2.122-2.122L11.5 7.464 7.964 11 4.43 7.464 5.843 6.05zM16 8c0 4.418-3.59 8-8 8-4.418 0-8-3.59-8-8 0-4.418 3.59-8 8-8 4.418 0 8 3.59 8 8zm-2 0c0-3.307-2.686-6-6-6-3.307 0-6 2.686-6 6 0 3.307 2.686 6 6 6 3.307 0 6-2.686 6-6z" fill-rule="evenodd"></path> </g></svg>';
          }
        }
      });
    }
  }

  updateVisitStatus(status, comment, checkboxValue, role) {
    const stackId = this.element.dataset.stackId;
    const studyId = this.element.dataset.studyId;
    // Make an Ajax request to update the visit_status
    const url = `${document.location.origin}/studies/${studyId}/stacks/${stackId}/update_status`;
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        visit_status: status,
        comment: comment,
        data_manager_lock: checkboxValue,
        role: role,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        this.showSuccessAlert(
          `Form ${status === "open" ? "unlocked" : "locked"} successfully!`
        );
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  updateAdditonalVisitStatus(status, comment, checkboxValue, role) {
    const stackId = this.element.dataset.stackId;
    const studyId = this.element.dataset.studyId;
    // Make an Ajax request to update the visit_status
    const url = `${document.location.origin}/studies/${studyId}/stacks/${stackId}/update_additional_status`;
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        additional_visit_status: status,
        comment: comment,
        data_manager_lock: checkboxValue,
        role: role,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        this.showSuccessAlert(
          `Form ${status === "open" ? "unlocked" : "locked"} successfully!`
        );
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  showSuccessAlert(message) {
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: message,
    }).then(() => {
      window.location.reload(true);
    });
  }

  showModal(event) {
    this.modal.classList.remove("hidden");
    const clickedElement = event.target;
    const studyId = clickedElement.getAttribute("data-study-id");

    // get stack_id and send it to the modal
    const stackId = clickedElement.getAttribute("data-stack-id");
    let stack = document.getElementById("stack_id");
    stack.value = stackId;

    // get comment_type and send it to the modal
    const id = clickedElement.id;
    let type = document.getElementById("comment_type");
    type.value = id;
    const sdv_verified = clickedElement.getAttribute("data-sdv-verified");
    const study_modality_grading_form_id =
      clickedElement.getAttribute("data-form-id");
    // Set the heading based on the comment type
    let heading = document.getElementById("heading");
    if (
      id == "qc_comment" ||
      id == "adjudication_comment" ||
      id == "other_adjudication_comment"
    ) {
      if (sdv_verified == "true") {
        this.checkCommentUrl =
          document.location.origin +
          `/studies/${studyId}/stacks/${stack.value}/check_sdv_status.json`;
        fetch(this.checkCommentUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-CSRF-Token": document.head
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({
            stack_id: stack.value,
            study_id: studyId,
            comment_type: type.value,
            study_modality_grading_form_id: study_modality_grading_form_id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.sdv_comment != "") {
              let sdv_comments = document.getElementById("sdv_comments");
              sdv_comments.innerHTML = data.sdv_comment;
              sdv_comments.setAttribute("readonly", true);
              document.getElementById("saveButton").classList.add("hidden");
              document
                .getElementById("revertButton")
                .classList.remove("hidden");
            }
            if (data.comment_type == "qc_comment") {
              heading.innerHTML = "Revert SDV Comments for QC";
            } else if (data.comment_type == "adjudication_comment") {
              heading.innerHTML = "Revert SDV Comments for Adjudication";
            } else if (data.comment_type == "other_adjudication_comment") {
              heading.innerHTML =
                "Revert SDV Comments for Additional Adjudication";
            }
          });
      } else {
        if (id == "qc_comment") {
          heading.innerHTML = "Add SDV Comments for QC";
        } else if (id == "adjudication_comment") {
          heading.innerHTML = "Add SDV Comments for Adjudication";
        } else if (id == "other_adjudication_comment") {
          heading.innerHTML = "Add SDV Comments for Additional Adjudication";
        }
      }
    }

    if (id == "grading_comment") {
      const gradingformId = clickedElement.getAttribute("data-gradingform-id");
      let gradingform_id = document.getElementById("gradingform_id");
      const study_modality_grading_form_id =
        clickedElement.getAttribute("data-form-id");
      gradingform_id.value = gradingformId;
      if (sdv_verified == "true") {
        this.checkCommentUrl =
          document.location.origin +
          `/studies/${studyId}/stacks/${stack.value}/check_sdv_status.json`;
        fetch(this.checkCommentUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-CSRF-Token": document.head
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({
            stack_id: stack.value,
            study_id: studyId,
            comment_type: type.value,
            gradingformId: gradingformId,
            study_modality_grading_form_id: study_modality_grading_form_id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.sdv_comment != "") {
              let sdv_comments = document.getElementById("sdv_comments");
              sdv_comments.innerHTML = data.sdv_comment;
              sdv_comments.setAttribute("readonly", true);
              document.getElementById("saveButton").classList.add("hidden");
              document
                .getElementById("revertButton")
                .classList.remove("hidden");
            }
            heading.innerHTML = "Revert SDV Comments for Grading";
          });
      }
      heading.innerHTML = "Add SDV Comments for Grading";
    }
    if (id == "eligibility_comment") {
      const gradingformId = clickedElement.getAttribute("data-gradingform-id");
      let gradingform_id = document.getElementById("gradingform_id");
      gradingform_id.value = gradingformId;
      if (sdv_verified == "true") {
        this.checkCommentUrl =
          document.location.origin +
          `/studies/${studyId}/stacks/${stack.value}/check_sdv_status.json`;
        fetch(this.checkCommentUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-CSRF-Token": document.head
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({
            stack_id: stack.value,
            study_id: studyId,
            comment_type: type.value,
            gradingformId: gradingformId,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.sdv_comment != "") {
              let sdv_comments = document.getElementById("sdv_comments");
              sdv_comments.innerHTML = data.sdv_comment;
              sdv_comments.setAttribute("readonly", true);
              document.getElementById("saveButton").classList.add("hidden");
              document
                .getElementById("revertButton")
                .classList.remove("hidden");
            }
            heading.innerHTML = "Revert SDV Comments for Eligibility";
          });
      }
      heading.innerHTML = "Add SDV Comments for Eligibility";
    }
    if (id == "other_grading_comment") {
      const gradingformId = clickedElement.getAttribute("data-gradingform-id");
      let gradingform_id = document.getElementById("gradingform_id");
      const study_modality_grading_form_id =
        clickedElement.getAttribute("data-form-id");
      gradingform_id.value = gradingformId;
      if (sdv_verified == "true") {
        this.checkCommentUrl =
          document.location.origin +
          `/studies/${studyId}/stacks/${stack.value}/check_sdv_status.json`;
        fetch(this.checkCommentUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-CSRF-Token": document.head
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
          },
          body: JSON.stringify({
            stack_id: stack.value,
            study_id: studyId,
            comment_type: type.value,
            gradingformId: gradingformId,
            study_modality_grading_form_id: study_modality_grading_form_id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.sdv_comment != "") {
              let sdv_comments = document.getElementById("sdv_comments");
              sdv_comments.innerHTML = data.sdv_comment;
              sdv_comments.setAttribute("readonly", true);
              document.getElementById("saveButton").classList.add("hidden");
              document
                .getElementById("revertButton")
                .classList.remove("hidden");
            }
            heading.innerHTML = "Revert SDV Comments for Additional Grading";
          });
      }
      heading.innerHTML = "Add SDV Comments for Addional Grading";
    }
  }

  saveSdvFormData() {
    let sdv_comments = document.getElementById("sdv_comments");
    let type = document.getElementById("comment_type");
    let stack = document.getElementById("stack_id");
    let gradingform_id = document.getElementById("gradingform_id");
    this.selectChangeUrl =
      document.location.origin +
      `/studies/${this.data.get("studyId")}/stacks/${
        stack.value
      }/save_sdv_comment.json`;
    if (!sdv_comments.value) {
      alert("Comments can not be empty");
    } else {
      fetch(this.selectChangeUrl, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": document.head
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        method: "PATCH",
        body: JSON.stringify({
          stack_id: stack.value,
          sdv_comments: sdv_comments.value,
          comment_type: type.value,
          gradingform_id: gradingform_id.value,
          study_modality_grading_form_id: this.data.get("formId"),
        }),
      }).then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Comments Added successfully",
          showConfirmButton: false,
          timer: 1000,
        });
        this.closeModal();
        window.location.reload();
      });
    }
  }

  revertSdvFormData() {
    let commentType = document.getElementById("comment_type");
    let stackId = document.getElementById("stack_id");
    let studyId = this.data.get("studyId");
    let gradingform_id = document.getElementById("gradingform_id");
    if (commentType.value && stackId.value) {
      Swal.fire({
        title: "Are you sure to revert this?",
        text: "This will be reverted permanently!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, revert it!",
        cancelButtonText: "Cancel", // Add Cancel button
      }).then((result) => {
        if (result.isConfirmed) {
          const selectChangeUrl =
            document.location.origin +
            `/studies/${studyId}/stacks/${stackId}/revert_sdv_comment.json`;
          console.log(selectChangeUrl);
          fetch(selectChangeUrl, {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-CSRF-Token": document.head
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            },
            method: "PATCH",
            body: JSON.stringify({
              stack_id: stackId.value,
              comment_type: commentType.value,
              gradingform_id: gradingform_id.value,
              study_modality_grading_form_id: this.data.get("formId"),
            }),
          }).then((response) => {
            Swal.fire(
              "Reverted!",
              "Your comment has been reverted.",
              "success"
            );
            window.location.reload();
          });
        }
      });
    } else {
      console.error("HTML elements not found");
    }
  }

  closeModal() {
    this.modal.classList.add("hidden");
    let sdv_comments = document.getElementById("sdv_comments");
    let type = document.getElementById("comment_type");
    let stack = document.getElementById("stack_id");
    stack.value = "";
    type.value = "";
    sdv_comments.value = "";
    location.reload();
  }

  toggleDropdown() {
    this.menulistTarget.classList.toggle("hidden");
  }

  keepMenuOpen() {
    clearTimeout(this.closeMenuTimeout);
  }

  closeMenu() {
    this.closeMenuTimeout = setTimeout(() => {
      this.menulistTarget.classList.add("hidden");
    }, 300);
  }
}
